import styled from 'styled-components'

export const Text = styled.div``

export const Headline = styled.div`
  font-size: ${({ theme }) => theme.font.size.lg};
  font-family: bold;
`

export const Wrapper = styled.div`
  width: calc(33% - 50px);

  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    width: 100%;
  }
`
