import { Container } from '@think-internet/ui-components'
import { Content, StyledSubpageHero } from './SubpageHero.Styled'

const SubpageHero = ({ children, bg }) => {
  return (
    <StyledSubpageHero>
      <Container>
        <Content bg={bg}>{children}</Content>
      </Container>
    </StyledSubpageHero>
  )
}

export default SubpageHero
