import { StyledItem, Box, Headline, SubHeadline, Text } from './ContactItem.Styled.js'

const ContactItem = ({ item }) => {
  return (
    <StyledItem>
      <Box>
        <Headline>{item.name}</Headline>
        <Text>{item.text}</Text>
      </Box>
      <Box>
        <SubHeadline>{item.sub}</SubHeadline>
      </Box>
    </StyledItem>
  )
}

export default ContactItem
