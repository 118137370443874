import processes from '../../assets/image/digitalization/sequence/processes.svg'
import communication from '../../assets/image/digitalization/sequence/communication.svg'
import data from '../../assets/image/digitalization/sequence/data.svg'
import concept from '../../assets/image/software/sequence/concept.svg'
import development from '../../assets/image/software/sequence/development.svg'
import { StyledPage, Headline, HeroWrapper, Section, SubHeadline, CenterSection, CustomContainer } from './Workshop.Styled.js'
import { useSelector } from 'react-redux'
import props from '../../redux/props'
import Layout from '../Layout/Layout'
import SequenceItem from './SequenceItem/SequenceItem.js'
import { getUUID } from '../../utility'
import TechnologieItem from './TechnologieItem/TechnologieItem.js'
import ProcessItem from './ProcessItem/ProcessItem.js'
import ContactItem from './ContactItem/ContactItem.js'

const Workshop = () => {
  const sequenceImages = [data, concept, processes, development, communication]
  const translation = useSelector((s) => s[props.TRANSLATION])
  const translationRootKey = 'customerWorkshop'

  return (
    <Layout authRequired={false}>
      <StyledPage>
        <CustomContainer>
          <HeroWrapper>
            <Headline>{translation[translationRootKey].headline}</Headline>
          </HeroWrapper>
          <SubHeadline>{translation[translationRootKey].subheadline}</SubHeadline>
          <Section>
            {translation[translationRootKey].sequence.items.map((item, index) => (
              <SequenceItem key={getUUID()} item={item} image={sequenceImages[index]} odd={index % 2} />
            ))}
          </Section>
          <SubHeadline>{translation[translationRootKey].technologies.title}</SubHeadline>

          <Section>
            {translation[translationRootKey].technologies.items.map((item) => (
              <TechnologieItem key={getUUID()} item={item} image={sequenceImages[0]} odd={false} />
            ))}
          </Section>
          <SubHeadline>{translation[translationRootKey].process.title}</SubHeadline>
          <CenterSection>
            <ProcessItem image={data} />
            <ProcessItem image={concept} />
          </CenterSection>
          <CenterSection>
            <ProcessItem image={processes} />
            <ProcessItem image={development} />
            <ProcessItem image={communication} />
          </CenterSection>
          <SubHeadline>{translation[translationRootKey].contact.title}</SubHeadline>
          <Section>
            {translation[translationRootKey].contact.items.map((item) => (
              <ContactItem key={getUUID()} item={item} />
            ))}
          </Section>
        </CustomContainer>
      </StyledPage>
    </Layout>
  )
}

export default Workshop
