import styled from 'styled-components'

export const StyledHero = styled.div`
  background-image: url(${({ bg }) => bg});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const Content = styled.div``
