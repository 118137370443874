import styled from 'styled-components'

export const StyledArticle = styled.div`
  display: block;
  width: 100%;
  margin-top: 25px;
  padding-top: 25px;
  border-top: 1px solid ${({ theme }) => theme.color.border.primary};
  text-decoration: none;
  &:first-child {
    border-top: none;
  }
`

export const Split = styled.div`
  display: flex;
  gap: 10px;
  @media (max-width: ${({ theme }) => theme.breakpoint.sm}) {
    flex-direction: column;
  }
`

export const ImageWrapper = styled.div`
  width: 30%;
  @media (max-width: ${({ theme }) => theme.breakpoint.sm}) {
    width: 100%;
  }
`

export const Image = styled.img`
  width: 100%;
`

export const Content = styled.div`
  width: 100%;
`

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

export const FormattedDate = styled.div`
  color: ${({ theme }) => theme.color.font.primary};
  font-size: ${({ theme }) => theme.font.size.sm};
`

export const Title = styled.div`
  color: ${({ theme }) => theme.color.font.primary};
  font-family: 'bold';
  font-size: ${({ theme }) => theme.font.size.lg};
  text-decoration: none;
`

export const Text = styled.div`
  color: ${({ theme }) => theme.color.font.primary};
  white-space: pre-wrap;
  margin-top: 15px;
`
