import { Container } from '@think-internet/ui-components'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import useCRUD from '../../../interface/useCRUD'
import props from '../../../redux/props'
import { getPrettyDate } from '../../../utility'
import Layout from '../../Layout/Layout'
import { StyledArticleView, Hero, PublicSince, Headline, Text } from './ArticleView.Styled'

const ArticleView = () => {
  const { uuid } = useParams()
  const [data, setData] = useState(null)
  const { get } = useCRUD(props.BLOG_ARTICLE)
  const translation = useSelector((s) => s[props.TRANSLATION])

  useEffect(() => {
    const fetchArticle = async () => {
      const result = await get({ uuid })
      if (result) {
        setData(result)
      }
    }
    if (!data) {
      fetchArticle()
    }
  })

  return (
    <Layout authRequired={false}>
      {data && (
        <StyledArticleView>
          {data.heroImageURL && <Hero src={data.heroImageURL} />}
          <Container>
            <PublicSince>
              {translation.news.article.createdOn}
              {getPrettyDate(new Date(data.createdOn))}
            </PublicSince>
            <Headline>{data.headline}</Headline>
            <Text>{data.text}</Text>
          </Container>
        </StyledArticleView>
      )}
    </Layout>
  )
}

export default ArticleView
