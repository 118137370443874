import styled from 'styled-components'
import indicatorOff from '../../../assets/image/generic/carousel/indicator-off.png'
import indicatorOn from '../../../assets/image/generic/carousel/indicator-on.png'

export const StyledCarousel = styled.div`
  height: 400px;
  position: relative;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    height: fit-content;
  }
`

export const Indicators = styled.div`
  position: absolute;
  bottom: -50px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 0px;
`

export const Indicator = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-image: url(${indicatorOff});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
  ${({ active, theme }) =>
    active &&
    `
    background-image: url(${indicatorOn});
  `}
  @media (max-width: ${({ theme }) => theme.breakpoint.sm}) {
    width: 30px;
    height: 30px;
  }
`
