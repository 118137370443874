import { Route, Routes } from 'react-router-dom'
import NewsView from './NewsView/NewsView'
import ArticleView from './ArticleView/ArticleView'

const News = () => {
  return (
    <Routes>
      <Route path={':uuid'} element={<ArticleView />} />
      <Route path={'*'} element={<NewsView />} />
    </Routes>
  )
}

export default News
