import styled from 'styled-components'
import separatorBG from '../../assets/image/home/separator/bg.png'

export const StyledHome = styled.div``

export const Separator = styled.div`
  margin-top: 50px;
  padding: 30px 0;
  background-image: url(${separatorBG});
  background-size: 80% 100%;
  background-repeat: no-repeat;
  background-position: left center;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    background-size: auto 100%;
  }
`
