import { useSelector } from 'react-redux'
import props from '../../../../redux/props'
import SmallContainer from '../../SmallContainer/SmallContainer'
import { StyledIntro, Title, Text } from './Intro.Styled'

const Intro = ({ translationRootKey }) => {
  const translation = useSelector((s) => s[props.TRANSLATION])

  return (
    <StyledIntro>
      <SmallContainer>
        <Title>{translation[translationRootKey].intro.title}</Title>
        <Text>{translation[translationRootKey].intro.text}</Text>
      </SmallContainer>
    </StyledIntro>
  )
}

export default Intro
