import { StyledReferences, Item, Image, Content, Title, Text, CarouselWrapper } from './References.Styled'
import rtlImage from '../../../assets/image/home/references/rtl.png'
import as24Image from '../../../assets/image/home/references/as24.png'
import chrono24Image from '../../../assets/image/home/references/chrono24.svg'
import akamaiImage from '../../../assets/image/home/references/akamai.svg'
import baykaImage from '../../../assets/image/home/references/bayka.png'
import uptodateImage from '../../../assets/image/home/references/uptodate.png'
import hermesImage from '../../../assets/image/home/references/hermes.svg'
import Headline from '../../SubComponents/Headline/Headline'
import { useSelector } from 'react-redux'
import props from '../../../redux/props'
import { getUUID } from '../../../utility'
import { Container } from '@think-internet/ui-components'
import { TextWrapper, ImageWrapper } from './References.Styled'
import Carousel from '../../SubComponents/Carousel/Carousel'

const References = () => {
  const translation = useSelector((s) => s[props.TRANSLATION])

  const images = [rtlImage, as24Image, hermesImage, chrono24Image, akamaiImage, baykaImage, uptodateImage]

  const getItems = () =>
    translation.home.references.items.map((item, i) => (
      <Item key={getUUID()}>
        <Content>
          <ImageWrapper>
            <Image src={images[i]} />
          </ImageWrapper>
          <TextWrapper>
            <Title>{item.title}</Title>
            <Text>{item.text}</Text>
          </TextWrapper>
        </Content>
      </Item>
    ))

  return (
    <StyledReferences>
      <Container>
        <Headline text={translation.home.references.headline} align="center" />
        <CarouselWrapper>
          <Carousel items={getItems()} />
        </CarouselWrapper>
      </Container>
    </StyledReferences>
  )
}

export default References
