import { useSelector } from 'react-redux'
import props from '../../../redux/props'
import { StyledTopicSubpage, BG } from './TopicSubpage.Styled'
import SubpageHero from '../SubpageHero/SubpageHero'
import Intro from './Intro/Intro'
import Sequence from './Sequence/Sequence'
import Technologies from './Technologies/Technologies'
import MottoBlock from '../MottoBlock/MottoBlock'
import ContactForm from '../ContactForm/ContactForm'
import Layout from '../../Layout/Layout'

const TopicSubpage = ({ translationRootKey, sequenceImages }) => {
  const translation = useSelector((s) => s[props.TRANSLATION])

  if (!translationRootKey || !Array.isArray(sequenceImages)) return <></>

  return (
    <Layout authRequired={false}>
      <StyledTopicSubpage>
        <BG>
          <SubpageHero>{translation[translationRootKey].headline}</SubpageHero>
          <Intro translationRootKey={translationRootKey} />
        </BG>
        <Sequence images={sequenceImages} translationRootKey={translationRootKey} />
        <MottoBlock />
        <Technologies translationRootKey={translationRootKey} />
        <ContactForm />
      </StyledTopicSubpage>
    </Layout>
  )
}

export default TopicSubpage
