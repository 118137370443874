import { Input, Textarea, Container } from '@think-internet/ui-components'
import { useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import Headline from '../Headline/Headline'
import { StyledContactForm, Content, ContentWrapper, CTA, InternalForm, Page, Split, Subline, Title, Value, Message } from './ContactForm.Styled'
import { sendMailEndpoint, recaptchaV3SiteKey } from '../../../utility'
import ReCAPTCHA from 'react-google-recaptcha'

const ContactForm = () => {
  const [data, setData] = useState({})
  const translation = useSelector((s) => s.TRANSLATION)
  const recaptchaRef = useRef(null)
  const [status, setStatus] = useState({})

  const updateProp = (prop) => (value) => setData({ ...data, [prop]: value })

  const submit = async () => {
    const token = await recaptchaRef.current.executeAsync()
    const response = await fetch(sendMailEndpoint, {
      method: 'post',
      body: JSON.stringify({ token, ...data }),
      headers: {
        'Content-Type': 'application/json',
      },
    })
    try {
      const responseJson = await response.json()
      if (responseJson.status === true) {
        setData({})
        setStatus({ status: 'success', message: translation.contactForm.success })
      } else {
        setStatus({ status: 'error', message: translation.contactForm.error })
      }
    } catch (e) {
      console.log(e)
      setStatus({ status: 'error', message: translation.contactForm.error })
    }
  }

  return (
    <StyledContactForm id="contact">
      <Container>
        <ContentWrapper>
          <Content>
            <Headline text={translation.contactForm.headline} />
            <Split>
              <Page>
                <Subline>{translation.contactForm.subline}</Subline>
                <Title>{translation.genericInfo.ceoName}</Title>
                <Value>{translation.genericInfo.ceoTitle}</Value>
                <Title>{translation.generic.mail}</Title>
                <Value href={`mailto:${translation.genericInfo.ceoMail}`}>{translation.genericInfo.ceoMail}</Value>
              </Page>
              <Page>
                <InternalForm onSubmit={submit}>
                  <Input
                    required
                    value={data.name}
                    onChange={updateProp('name')}
                    placeholder={`${translation.generic.firstname} ${translation.generic.lastname}`}
                  />
                  <Input required type="email" value={data.mail} onChange={updateProp('mail')} placeholder={translation.generic.mail} />
                  <Textarea required rows="5" value={data.message || ''} onChange={updateProp('message')} placeholder={translation.generic.message} />
                  {status && status.message && <Message>{status.message}</Message>}
                  <CTA type="submit">{translation.contactForm.cta}</CTA>
                  <ReCAPTCHA ref={recaptchaRef} size="invisible" sitekey={recaptchaV3SiteKey} />
                </InternalForm>
              </Page>
            </Split>
          </Content>
        </ContentWrapper>
      </Container>
    </StyledContactForm>
  )
}

export default ContactForm
