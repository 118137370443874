import Layout from '../Layout/Layout'
import { StyledHome, Separator } from './Home.Styled.js'
import Intro from './Intro/Intro'
import WhoWeAre from './WhoWeAre/WhoWeAre'
import Perfection from './Perfection/Perfection'
import Split from './Split/Split'
import Services from './Services/Services'
import References from './References/References'
import ContactForm from '../SubComponents/ContactForm/ContactForm'
import MottoBlock from '../SubComponents/MottoBlock/MottoBlock'

const Home = () => {
  return (
    <Layout authRequired={false} subpage={false}>
      <StyledHome>
        <Intro />
        <WhoWeAre />
        <Perfection />
        <Split />
        <Separator />
        <Services />
        <MottoBlock />
        <References />
        <ContactForm />
      </StyledHome>
    </Layout>
  )
}

export default Home
