import { Image, StyledItem, Column, Headline, Wrapper } from './Item.Styled.js'

const Item = ({ item, image, odd }) => {
  return (
    <Wrapper odd={odd}>
      <StyledItem>
        <Image src={image} />
        <Column>
          <Headline>{item.name}</Headline>
        </Column>
      </StyledItem>
    </Wrapper>
  )
}

export default Item
