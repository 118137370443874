import { Container } from '@think-internet/ui-components'
import { useSelector } from 'react-redux'
import props from '../../redux/props'
import Layout from '../Layout/Layout'
import SubpageHero from '../SubComponents/SubpageHero/SubpageHero'
import { StyledGTC, Subheadline, P, Strong } from './GTC.Styled'

const GTC = () => {
  const translation = useSelector((s) => s[props.TRANSLATION])

  return (
    <Layout authRequired={false}>
      <StyledGTC>
        <SubpageHero>{translation.gtc.headline}</SubpageHero>
        <Container>
          <Container>
            <Subheadline>
              ALLGEMEINE GESCHÄFTSBEDINGUNGEN (AGB) THINK INTERNET OPERATIONS GMBH, ULMENWEG 2, 87779 TRUNKELSBERG (NACHFOLGEND AUCH: THINK INTERNET
              OPERATIONS)
            </Subheadline>
            <Subheadline>
              <Strong>Teil I: Allgemeine Bestimmungen für Lieferungen und Leistungen</Strong>
            </Subheadline>
            <P>
              <Strong>§ 1 Geltungsbereich</Strong>
              <br />
              1. Diese Allgemeinen Geschäftsbedingungen (folgend Geschäftsbedingungen) gelten für alle Lieferungen und Leistungen der Think Internet
              Operations. Die nachfolgenden Geschäftsbedingungen gelten auch für alle zukünftigen Geschäftsbeziehungen, auch wenn sie nicht nochmals
              ausdrücklich vereinbart werden. Abweichenden oder ergänzenden Geschäftsbedingungen des Kunden wird hiermit widersprochen.
              <br />
              2. Die Nutzungsbedingungen oder sonstige Bedingungen der Dritthersteller gelten vorrangig vor diesen Geschäftsbedingungen, soweit Think
              Internet Operations unter diesen Geschäftsbedingungen Drittprodukte z.B. Software liefert. Der Kunde ist verpflichtet, die nutzungs- und
              urheberrechtlichen Bestimmungen der jeweiligen Hersteller und/oder Lieferanten einzuhalten.
            </P>
            <P>
              <Strong>§ 2 Leistungen von Think Internet Operations</Strong>
              <br />
              1. Das Leistungsspektrum von Think Internet Operations umfasst die Beratung und den Support und weitere Dienstleistungen rund um
              Clouddienste und Softwareentwicklung.
              <br />
              2. Der einzelne Leistungsumfang bestimmt sich ausschliesslich nach den individuellen Vereinbarungen der Parteien, die sich aus dem
              Angebot und der Auftragsbestätigung ergeben.
              <br />
              3. Die Änderung des Leistungsumfangs bedarf der ausdrücklichen Zustimmung des anderen Vertragpartners. Dies bedarf der Textform.
              <br />
              4. Erfordert die Änderung des Leistungsumfangs des Kunden eine umfangreiche Überprüfung, so ist diese gesondert zu vereinbaren und wird
              von Think Internet Operations gesondert berechnet.
              <br />
              5. Die für eine Überprüfung und/oder Änderung erforderlichen vertraglichen Anpassungen werden in Textform festgelegt. Sie bilden, auch
              wenn Sie mit den ursprünglichen Vertrag nicht fest verbunden sind, einen einheitlichen Vertrag.
              <br />
              6. Think Internet Operations erbringt die vertraglich vereinbarten Leistungen nach den Grundsätzen ordnungsgemäßer Berufsausübung mit
              qualifiziertem Personal und führt die Lieferungen (z.B. Software) und Leistungen (z.B. Installation, Support) eigenverantwortlich durch.
              <br />
              7. Die Angebote von Think Internet Operations sind grundsätzlich freibleibend.
              <br />
              8. Think Internet Operations kann für die Leistungserbringung einen oder mehrere Subunternehmer einsetzen. Subunternehmer sind nicht mit
              Think Internet Operations verbundene Unternehmen.
            </P>
            <P>
              <Strong>§ 3 Rechte und Pflichten des Kunden</Strong>
              <br />
              1. An der Software, die der Kunde Think Internet Operations im Rahmen des Vertrages unentgeltlich zur Verfügung stellt, erhält Think
              Internet Operations ein nicht übertragbares, nicht ausschließliches Recht zur Nutzung auf dem z.B. im Rechenzentrum von Think Internet
              Operations gehosteten Server bzw. zur Benutzung einer Kopie der Software auf Einzelcomputern im vertraglich
              <br />
              2 Version 1.1 – September 2019
              <br />
              vereinbarten Umfang. Sämtliche Rechte bezüglich der Dokumentation der Software verbleiben beim Kunden oder dessen Lieferanten. Der Kunde
              ist für das Vorliegen und die Beschaffung ausreichender Nutzungsrechte allein verantwortlich.
              <br />
              2. Sofern im Rahmen von § 69 e UrhG erforderlich, wird der Kunde von den Lizenzgebern, von denen er die Software erworben hat, die
              Offenlegung von Schnittstellen verlangen.
              <br />
              3. Die Ziffern 1 und 2 gelten auch, sofern und soweit Think Internet Operations die Software bei dem jeweiligen Lizenzgeber für den
              Kunden erwirbt.
              <br />
              4. Der Kunde ist verpflichtet, den Grundsätzen der Datensicherheit Rechnung zu tragen, insbesondere Zugänge (z.B. VPN) zum Kundennetz
              durch Think Internet Operations Mitarbeiterinnen/Mitarbeiter nach Kenntniserlangung des Ausscheidens dieser aus dem Think Internet
              Operations Unternehmen selbständig zu deaktivieren und Passwörter geheim zu halten bzw. unverzüglich zu ändern oder Änderungen zu
              veranlassen, falls die Vermutung besteht, dass nichtberechtigte Dritte davon Kenntnis erlangt haben.
              <br />
              5. Der Kunde trägt selbständig Sorge für die Erfüllung bzw. Einhaltung gesetzlicher Vorschriften und behördlicher Auflagen sowie die
              Erteilung behördlicher Erlaubnisse, soweit diese gegenwärtig oder künftig für z.B. die Teilnahme am Internet oder dem Think Internet
              Operations-Netz erforderlich sein sollten.
            </P>
            <P>
              <Strong>§ 4 Mitwirkungspflichten des Kunden</Strong>
              <br />
              1. Jede Partei benennt jeweils einen verantwortlichen Ansprechpartner.
              <br />
              2. Der Kunde wird während der Vorbereitung und Durchführung der Lieferungen und Leistungen Think Internet Operations jede notwendige und
              zumutbare Unterstützung (z.B. Beistellungen, Zugang zu den Produkten,Stromversorgung, Stellflächen für Service und
              Technikereinrichtungen) gewähren und in seiner Betriebssphäre alle zur ordnungsgemäßen Leistungserbringung erforderlichen
              Voraussetzungen und Vorbereitungsmaßnahmen schaffen. 3. Der Kunde ist für die ordnungs- gemäße Nutzung und für angemessene
              Umgebungsbedingungen der in den Vertrag einbezogenen Produkte und Programme selbst verantwortlich.
              <br />
              3. Bei Vor-Ort-Einsätzen gewährleistet der Kunde die Einhaltung aller arbeits- schutzrechtlichen Bestimmungen. Der Kunde wird
              sicherstellen, dass das Benachteiligungsverbot in § 7 AGG gegenüber den Erfüllungsgehilfen und Mitarbeiterinnen/Mitarbeitern von Think
              Internet Operations gewahrt wird.
              <br />
              4. Erfüllt der Kunde seine Mitwirkungspflichten nicht, verspätet oder nicht ordnungsgemäß, verlängern sich die vereinbarten Liefer- und
              Leistungsfristen in angemessenem Rahmen. Vereinbarte Service-Level mit ggf. vereinbarten Vertragsstrafen werden ausgesetzt und gelten
              nicht. Ferner tritt auf Seiten der Think Internet Operations kein Verzug ein.
              <br />
              5. Aufwendungen, Kosten, Nachteile und Schäden, die aus der Verletzung dieser Ziffern und/oder aus in sonstigen Vertragsdokumenten
              genannten Mitwirkungspflichten des Kunden resultieren, gehen zu Lasten des Kunden. Mitwirkungspflichten sind Hauptleistungspflichten.
            </P>
            <P>
              <Strong>§ 5 Software</Strong>
              <br />
              1. Stellt der Kunde Think Internet Operations Software zur Verfügung bzw. spielt Think Internet Operations im Auftrag des Kunden die
              Software auf die Hardware auf, sichert der Kunde zu, die hierfür erforderlichen Nutzungsrechte (Lizenzen) erworben zu haben.
              <br />
              2. Kann der Hersteller oder Lieferant bereits bestellte Produkte nicht mehr liefern, sondern lediglich Nachfolge-Modelle, die jedoch
              hinsichtlich der Funktionalität und der Qualität vergleichbar oder ähnlich sind und die vom Kunden geforderten Spezifikationen erfüllen,
              kann Think Internet Operations das Nachfolgemodell zur Vertragserfüllung liefern.
              <br />
              3. Think Internet Operations behält sich die Möglichkeit von Teillieferungen vor, soweit diese für den Kunden zumutbar sind.
              <br />
              4. Think Internet Operations ist berechtigt, Programm-, Installations- und Produktdokumentationen sowie sonstige Unterlagen in
              englischer Sprache zu liefern, sofern diese nicht in deutscher Sprache verfügbar sind.
              <br />3 Version 1.1 – September 2019
            </P>
            <P>
              <Strong>§ 6 Leistungszeit, Verzug und Nichtleistung</Strong>
              <br />
              1. Dem Kunden übermittelte oder mit ihm vereinbarte Lieferdaten und Termine zur Erbringung von Leistungen gelten als Richtwerte und
              setzen die Abklärung aller technischen Fragen und der Einzelheiten der Ausführung voraus. Liefer- und Leistungstermine sind nur dann
              verbindlich, wenn diese zwischen den Parteien in Schriftform (E-Mail ist nicht ausreichend) verbindlich bezeichnet worden sind und setzt
              voraus, dass der Kunde seine vertraglichen Pflichten sowie seine Mitwirkungspflichten ordnungsgemäß und rechtzeitig erfüllt.
              <br />
              2. Hält Think Internet Operations verbindliche Leistungstermine nicht ein, so hat der Kunde zunächst schriftlich eine angemessene
              Nachfrist mit dem Hinweis zu setzen, dass er nach fruchtlosem Ablauf dieser Frist die Vertragserfüllung ablehne. Nach erfolglosem Ablauf
              dieser Nachfrist kann der Kunde von dem betreffenden Vertrag zurücktreten oder ihn kündigen.
              <br />
              3. Die Leistungserbringung steht außerdem unter dem Vorbehalt, dass notwendige Ersatzteile oder -geräte allgemein erhältlich und beim
              Lieferanten / Hersteller vorrätig sind. Think Internet Operations ist berechtigt, die Leistungserbringung zu unterbrechen, wenn der
              Kunde auf zwei aufeinander folgenden Terminen mit der Entrichtung der vereinbarten Vergütung oder eines nicht unerheblichen Teils der
              Vergütung in Verzug ist. Think Internet Operations wird den Kunden mindestens 48 Stunden vor Leistungsunterbrechung informieren. Nach
              Zahlung der rückständigen Beträge wird Think Internet Operations die Leistungs- erbringung wieder aufnehmen. Der Kunde bleibt zur
              Zahlung der vereinbarten Vergütung, auch für die Zeit der Leistungsunterbrechung, abzüglich ersparter Aufwendungen der Think Internet
              Operations, verpflichtet. Vereinbarte Termine
              <br />
              4. (z.B. Meilensteine im Projekt) verschieben sich entsprechend und/oder Service-Level-Zeiten gelten in diesem Fall nicht.
              <br />
              5. Liefer- und Leistungstermine verschieben sich entsprechend, wenn Think Internet Operations infolge höherer Gewalt oder sonstiger
              Ereignisse, die außerhalb des Einflussbereiches von Think Internet Operations liegen und trotzdem zumutbarer Sorgfalt nicht abgewendet
              werden können (z.B. Krieg, Aufruhr, Streiks, Feuer, Überschwemmung, nicht vorhersehbare Betriebsstörungen, auch bei Zulieferern, Ausfall
              von und Störungen in Kommunikationsnetzen anderer Betreiber) gehindert wird, die vertraglichen Pflichten zu erfüllen. Think Internet
              Operations wird dies dem Kunden unverzüglich mitteilen. Jede Partei kann von dem Vertrag zurücktreten oder ein Dauerschuldverhältnis
              kündigen, wenn das betreffende Ereignis länger als zwei Monate andauert.
            </P>
            <P>
              <Strong>§ 7 Preise, Zahlungsbedingungen, Aufrechnung, Steuern</Strong>
              <br />
              1. Alle Preise sind Netto-Preise zuzüglich der aktuellen gesetzlichen Umsatzsteuer.
              <br />
              2. Sofern nichts anderes vereinbart wird, sind Rechnungen sofort nach Rechnungserhalt ohne Abzug zu zahlen. 3. Bei Auslandsgeschäften,
              sind die vereinbarten Preise Nettopreise, d.h. das ist der vom Kunden zu bezahlende Nettopreis nach Abzug etwaiger ausländischer
              Steuern. Der Begriff „ausländische Steuern“ meint insbesondere Körperschaftsteuer, Gewerbesteuer, Umsatzsteuer, Waren- und
              Servicesteuer, andere Quellensteuern, Zölle oder andere Zuschläge und Kosten, sowie sonstige Gebühren und Abgaben, die von einem
              ausländischen Staat oder einer ausländischen Gemeinde erhoben werden. Daher vereinbaren die Parteien, dass alle ausländische Steuern
              vollständig vom Kunden übernommen und bezahlt werden. Der Kunde verpflichtet sich, Think Internet Operations alle erforderlichen
              Steuerbescheinigungen, Steuerbescheide und alle weiteren Dokumente bereitzustellen, die von Think Internet Operations benötigt werden,
              um seine steuerrechtlichen Verpflichtungen im Ausland und in Deutschland zu erfüllen.
              <br />
              3. Im Falle einer Erhebung, Erhöhung oder Änderung insbesondere öffentlich-rechtlicher Abgaben, der Umsatzsteuer, der
              Urheberrechtsvergütung einschließlich einer gegebenenfalls auf die Urheberrechtsvergütung anfallenden Umsatzsteuer, behält sich Think
              Internet Operations vor, die vertraglich vereinbarten Preise (Vergütung nach Aufwand oder Festpreis) sowie die Vergütungen entsprechend
              zu erhöhen. Think Internet Operations teilt dem Kunden die Preiserhöhung mit.
              <br />
              4. Think Internet Operations ist bei Dauerschuldverhältnissen berechtigt, bei sich veränderden Marktbedingungen, bei erheblichen
              Veränderungen der Beschaffungskosten, wie Preiserhöhungen der Lizenzgeber, Energieversorger, Personal – und Materialkosten und Kosten
              der Telekommunikationsdienstleister die vereinbarten Preise unter Einhaltung einer Ankündigungsfrist von 2 Monaten zu erhöhen. Sofern
              die Preiserhöhung mehr als 5 % des ursprünglichen Preises übersteigt, ist der Kunde berechtigt, den Vertrag zum Ablauf des nächsten
              Kalendermonats nach Mitteilung der Erhöhung zu kündigen.
              <br />
              5. Der Kunde kann nur mit unbestrittenen oder rechtskräftig festgestellten Forderungen aufrechnen oder eine Zurückbehaltung ausüben,
              wenn sein Gegenanspruch auf dem gleichen Vertragsverhältnis beruht.
              <br />4 Version 1.1 – September 2019
            </P>
            <P>
              <Strong>§ 8 Eigentumsvorbehalt, Rechte</Strong>
              <br />
              1. Think Internet Operations behält sich das Eigentum und die Rechte an den vertragsgegenständlichen Lieferungen und Leistungen bis zur
              vollständigen Zahlung aller geschuldeten Forderungen und/oder aller Ansprüche aus der Geschäftsbeziehung vor (folgend Vorbehaltsware).
              <br />
              2. Der Weiterverkauf der von Think Internet Operations gelieferten Produkte darf nur unter Eigentumsvorbehalt erfolgen. Der Kunde tritt
              bereits jetzt seinen Kaufpreisanspruch aus zukünftiger Veräußerung sicherungshalber an Think Internet Operations ab. Auf Verlangen hat
              der Kunde die Abtretung schriftlich zu bestätigen. Der Kunde ist zum Einziehen der an Think Internet Operations abgetretenen Forderung
              ermächtigt, nicht aber zu Verfügungen anderer Art (z.B. Verpfändung, Sicherungsübereignung). Diese Ermächtigung ist jederzeit
              widerruflich.
              <br />
              3. Bei einer Pfändung oder jeder anderen Beeinträchtigung der Rechte von Think Internet Operations durch Dritte, insbesondere bei
              Zugriffen auf die Vorbehaltsware, ist der Kunde verpflichtet, den Dritten auf die Rechte von Think Internet Operations hinzuweisen und
              Think Internet Operations unverzüglich zu informieren. Nachteile, Kosten und Schäden durch die Verletzung dieser Pflicht gehen zu Lasten
              des Kunden.
              <br />
              4. Übersteigt der Wert der an Think Internet Operations gegebenen Sicherheiten die Forderungen von Think Internet Operations um mehr als
              20%, kann Think Internet Operations auf Verlangen des Kunden einen entsprechenden Anteil der Sicherungsrechte freigeben.
            </P>
            <P>
              <Strong>§ 9 Annahme, Abnahme, Gefahrenübergang</Strong>
              <br />
              1. Bei Annahmeverzug oder bei Verletzung sonstiger Mitwirkungspflichten durch den Kunden ist Think Internet Operations be- rechtigt, die
              Produkte auf Gefahr und Kosten des Kunden angemessen einzulagern.
              <br />
              2. Sofern der Kunde etwaige vereinbarte Vor-Ort-Termine nicht einhält, ist Think Internet Operations berechtigt, ihm die Kosten für
              diesen Einsatz entsprechend der vereinbarten oder üblichen Stundensätze in Rechnung zu stellen. Dem Kunden bleibt nachgelassen,
              nachzuweisen, dass Think Internet Operations die Mitarbeiter anderweitig einsetzen konnte.
              <br />
              3. Der Kunde ist nicht berechtigt, von Think Internet Operations erbrachte Werkleistungen vor schriftlicher Abnahme produktiv zu nutzen.
              Nutzt der Kunde sie dennoch produktiv, gilt der produktive Einsatz als Abnahme.
              <br />
              4. Mit Übergabe der verkauften IT- Produkte an den Kunden oder an ein etwaiges Auslieferungsunternehmen, geht unabhängig von noch zu
              erbringenden Werk- und/oder Dienstleistungen im Zusammenhang mit den verkauften IT-Produkten, die Gefahr des zufälligen Untergangs und
              der zufälligen Verschlechterung auf den Kunden über. Es wird klargestellt, dass es sich beim Kauf der IT-Produkte und der Erbringung von
              etwaigen Dienst- und/oder Werkleistungen um jeweils getrennte Rechtsgeschäfte handelt.
            </P>
            <P>
              <Strong>§ 10 Mängelhaftung, Garantie</Strong>
              <br />
              1. Der Kunde ist verpflichtet, die von Think Internet Operations erbrachte Leistung oder erhaltene Produkte umgehend auf Mangelfreiheit
              zu überprüfen und einen etwaigen Mangel unverzüglich in Textform anzuzeigen. Unterlässt der Kunde die Anzeige, so gilt das Produkt oder
              die Leistung als genehmigt, es sei den, es handelt sich um einen Mangel, der bei angemessener Prüfung nicht erkennbar war.
              <br />
              2. Der Kunde kann Nacherfüllung bei Vorliegen eines gem. §10 Nr. 1 angezeigten Mangels verlangen. Think Internet Operations wird nach
              eigener Wahl entweder eine Nachbesserung oder Neulieferung (Nacherfüllung) vornehmen, sofern dem Kunden nicht nur eine bestimmte Art der
              Nacherfüllungsversuche zumutbar ist. Bei Fehlschlägen zweier Nacherfüllungsversuche ist der Kunde verpflichtet, schriftlich eine
              angemessene Nachfrist zur Mängelbeseitigung zu setzen. Nach Fristverstreichung oder bei erneutem Fehlschlagen kann der Kunde vom Vertrag
              zurücktreten oder die Vergütung mindern, sofern ein erheblicher Mangel vorliegt. Tritt der Kunde vom Vertrag zurück, erstattet Think
              Internet Operations dem Kunden die bereits entrichtete Vergütung abzüglich einer von Think Internet Operations im eigenen Ermessen
              festgesetzten angemessenen Nutzungsentschädigung. Dem Kunden bleibt nachgelassen, nachzuweisen, dass Think Internet Operations keine
              oder eine geringere Entschädigung zusteht.
              <br />
              3. Nacherfüllungsansprüche bestehen insbesondere bei solchen Mängeln nicht, die durch eine Veränderung der Liefer-
              <br />
              5 Version 1.1 – September 2019
              <br />
              und/oder Leistungsprodukte, unsachgemäße Nutzung, natürlichen Verschleiß, Versagen der Komponenten der Systemumgebung, Bedienungsfehler,
              Nachbesserung/Änderungen des Kunden oder Dritter, durch unzureichende Wartung, durch Produkte Dritter oder Installation und/oder durch
              Gebrauch der Liefer- und Leistungsgegenstände entgegen den Hersteller- Richtlinien, entstehen.
              <br />
              4. Die verschuldensunabhängige Haftung von Think Internet Operations nach § 536 a Absatz 1 BGB wegen Mängel, die bereits zum Zeitpunkt
              des Vertragsschlusses vorhanden waren, ist ausgeschlossen.
              <br />
              5. Eine Kündigung durch den Kunden gemäß § 543 Absatz 2 Satz 1 Nr. 1 BGB wegen Nichtgewährung der vertragsgemäßen Nutzung der Leistung
              ist erst zulässig, wenn Think Internet Operations ausreichende Gelegenheit zur Mängelbeseitigung gegeben wurde und diese fehlgeschlagen
              ist. Von einem Fehlschlagen der Mängelbeseitigung ist erst auszugehen, wenn diese unmöglich ist, von Think Internet Operations
              verweigert oder in unzumutbarer Weise verzögert wird, begründete Zweifel bezüglich der Erfolgsaussichten bestehen oder diese aus anderen
              Gründen für den Kunden unzumutbar ist.
              <br />
              6. § 439 Abs. 3 BGB ist ausgeschlossen, es sei denn, Think Internet Operations hat den Mangel arglistig verschwiegen oder eine
              garantierte Beschaffenheit für die Produkte übernommen
              <br />
              7. Die Verjährungsfrist für Mangelansprüche, einschließlich Schadensersatz- und Aufwendungs- ersatzansprüchen, beträgt 1 Jahr ab
              Ablieferung bzw. Abnahme, soweit das Gesetz nicht zwingend längere Fristen vorsieht.
              <br />
              8. Think Internet Operations übernimmt Garantien für die Beschaffenheit der Lieferungen und Leistungen nur, soweit dies zwischen den
              Parteien ausdrücklich schriftlich vereinbart ist. Sofern in Angeboten, Dokumenten oder in sonstigen Unterlagen die Begrifflichkeiten
              “sichert zu”, “sicherstellen”, “stellt sicher”, „garantiert“ oder vergleichbare Begrifflichkeiten verwendet werden, sind sich die
              Parteien darüber einig, dass es sich hierbei nicht um Zusicherungen bzw. Garantien handelt, deren Nichteinhaltung zu einer
              verschuldensunabhängigen, unbeschränkten Haftung führt. Die Parteien sind sich weiterhin darüber einig, dass vereinbarte Reaktions- und
              Wiederherstellungszeiten und vereinbarte Verfügbarkeiten keine Garantien gemäß §§ 443, 639 BGB darstellen. 9. Öffentliche Äußerungen, z.
              B. Werbeaussagen der Hersteller, Dritter oder Lieferanten, stellen keine vertraglich vereinbarte Beschaffenheit dar.
            </P>
            <P>
              <Strong>§ 11 Haftung</Strong>
              <br />
              1. Think Internet Operations haftet unbegrenzt für die von Think Internet Operations sowie seinen gesetzlichen Vertretern oder
              Erfüllungsgehilfen vorsätzlich oder grob fahrlässig verursachten Schäden, für Schäden aus der Verletzung des Lebens, des Körpers oder
              der Gesundheit, nach dem Produkthaftungsgesetz und bei arglistigem Verhalten oder einer Garantie.
              <br />
              2. Think Internet Operations haftet bei leichter Fahrlässigkeit für solche Schäden, die aus der Verletzung einer wesentlichen
              Vertragspflicht resultieren, und zwar beschränkt auf den typischen, vorhersehbaren Schaden. In diesen Fällen ist die Haftung für die
              Summe aller Haftungsfälle innerhalb eines Kalenderjahres auf den Nettojahresauftragswert des Kalenderjahres begrenzt, in dem die
              Haftungsfälle eintreten. Wesentliche Vertragspflichten sind solche, deren Erfüllung die ordnungsgemäße Durchführung des Vertrages
              überhaupt erst ermöglicht und auf deren Einhaltung der Kunde regelmäßig vertraut oder vertrauen darf oder deren Verletzung die
              Erreichung des Vertragszwecks gefährdet.
              <br />
              3. Muss eine EDV-Anlage oder ein Teil davon zu Reparatur- oder Wartungszwecken während der produktiven Zeit des Kunden ausgeschaltet
              oder in sonstiger Weise beeinträchtigt werden, so übernimmt Think Internet Operations keine Haftung für Schäden und Nachteile, die
              daraus entstehen. Der Kunde kann allerdings auf eigene Verantwortung ausdrücklich verlangen, dass Think Internet Operations geschuldete
              Reparatur- oder Wartungsarbeiten zu bestimmten Zeiten nicht vornimmt.
              <br />
              4. Bei Verlust von Daten haftet Think Internet Operations nur dann, wenn der Kunde durch eine ordnungsgemäß durchgeführte Datensicherung
              sichergestellt hat, dass diese Daten durch einen vertretbaren Aufwand rekonstruiert werden können und die Datensicherung nicht
              Bestandteil der vertraglich vereinbarten Leistungen ist. Die Haftung ist der Höhe nach auf den Aufwand der Wiederherstellung begrenzt.
              <br />
              5. Im Übrigen ist die Haftung ausgeschlossen.
              <br />
              6. Die Haftungsbegrenzungen der Ziffern 2 – 5 gelten für Aufwendungsersatzansprüche, für Ansprüche aus Selbstvornahme, Kündigung und
              sonstige Haftungsansprüche des Kunden gegenüber Think Internet Operations. Die Ansprüche verjähren – mit Ausnahme der Ziffer 1 – ein
              Jahr nach Kenntniserlangung vom schädigenden Ereignis.
              <br />
              7. Der Ausschluss oder die Beschränkung der Schadensersatzhaftung gemäß den vorstehenden Bestimmungen gilt auch für etwaige Ansprüche
              des Kunden gegenüber Mitarbeiterinnen/Mitarbeiter der Think Internet Operations.
            </P>
            <P>
              <Strong>§ 12 Schutz- und Nutzungsrechte</Strong>
              <br />
              Aufschiebend bedingt mit vollständiger Zahlung aller Ansprüche räumt Think Internet Operations dem Kunden an den im Rahmen und im
              Zusammenhang der Leistungserbringung speziell und individuell erstellten Arbeitsergebnissen ein nicht ausschließliches, auf die den
              vertraglich vereinbarten Zweck innerhalb des Geschäftsbetriebes nicht übertragbares Nutzungsrecht ein. Die Nutzung ist in dem Umfang
              gestattet, der zur Erfüllung des vertraglich vorgesehenen Zwecks innerhalb des
              <br />
              6 Version 1.1 – September 2019
              <br />
              Geschäftsbetriebes des Kunden erforderlich ist.
            </P>
            <P>
              <Strong>§ 13 Geheimhaltung, Datenschutz, Auftragsverarbeitung</Strong>
              <br />
              1. Die Parteien verpflichten sich, alle nicht offenbarten oder öffentlich bekannten vertraulichen Informationen, die ihnen im
              Zusammenhang mit der Vorbereitung und Durchführung des Vertrages bekannt werden, über den Inhalt des Vertrages sowie alle Produkt,
              Betriebs- und Geschäftsgeheimnisse der jeweils anderen Partei − auch nach Beendigung des Vertrages streng vertraulich zu behandeln,
              nicht an Dritte weiterzugeben und nur zum Zwecke der Vertragsabwicklung zu verwenden.
              <br />
              2. Jede Partei ist verpflichtet, die Bestimmungen der Datenschutzgesetze zu beachten.
              <br />
              3. Soweit Think Internet Operations personenbezogene Daten im Auftrag des Kunden verarbeitet, nutzt oder die Möglichkeit des Zugriffs
              auf diese Daten besteht, wird Think Internet Operations diese Daten gemäß Art. 28 DS-GVO weisungsgebunden verarbeiten. Die Parteien
              schließen hierzu einen gesonderten Auftragsverarbeitungsvertrag.
            </P>
            <P>
              <Strong>§ 14 Exportbeschränkungen</Strong>
              <br />
              Die gelieferten Produkte, technisches know how und Serviceleistungen können Technologien und Software enthalten, die den jeweils auf sie
              anwendbaren Vorschriften des Außenwirtschaftsgesetzes der Bundesrepublik Deutschland sowie Exportkontrollvorschriften der Vereinigten
              Staaten von Amerika oder der Länder, in die Produkte geliefert oder in denen sie genutzt werden, unterliegen. Der Kunde verpflichtet
              sich, sich selbständig über die aktuellen Bestimmungen und Verordnungen zu informieren und diese zu beachten. Ohne vorherige behördliche
              Genehmigung ist es dem Kunden nicht gestattet, gelieferte Produkte direkt oder indirekt in Länder, die einem US-Embargo unterliegen,
              oder an natürliche oder juristische Personen, die auf US-amerikanischen, europäischen oder nationalen Verbotslisten (z.B. Entity List,
              Denied Persons List, Specifically Designated Nationals and Blocked Persons) stehen, zu liefern.
            </P>
            <Subheadline>
              <Strong>Teil II: Besondere Bedingungen für Serviceleistungen</Strong>
            </Subheadline>
            <P>
              Ergänzend zu den Bestimmungen in Teil I dieser Geschäftsbedingungen gelten die nachfolgenden Bestimmungen, sofern Think Internet
              Operations Serviceleistungen erbringt.
              <br />7 Version 1.1 – September 2019
            </P>
            <P>
              <Strong>§ 15 Umfang der Serviceleistungen</Strong>
              <br />
              1. Die zu erbringenden Serviceleistungen beziehen sich ausschließlich auf die im Vertrag hinsichtlich Hersteller, Typ sowie Serien- und
              Gerätenummer näher spezifizierten Produkte oder Systemkonfigurationen.
              <br />
              2. Die Serviceleistungen erbringt Think Internet Operations nach eigenem Ermessen und Auswahl. Sofern nichts anderes zwischen den
              Parteien schriftlich vereinbart ist, erbringt Think Internet Operations die Serviceleistung entweder telefonisch oder vor Ort beim
              Kunden. Nach Absprache mit dem Kunden kann eine Fernwartungslösung implementiert werden. Keine Verantwortung übernimmt Think Internet
              Operations für die Funktionsfähigkeit und Sicherheit der Fernwartungsleitungen außerhalb ihres Geschäftsbetriebs, es sei denn, die
              Parteien haben schriftlich etwas anderes vereinbart.
              <br />
              3. Bei Releasewechsel muss der Kunde die entsprechenden Nutzungsrechte (Lizenzen) für die zu installierende Software haben bzw. diese
              erwerben
              <br />
              4. Der Kunde ist verpflichtet, Änderungen des Aufstellungsortes der betroffenen Produkte oder Systeme Think Internet Operations
              rechtzeitig schriftlich mitzuteilen. Beabsichtigt der Kunde, die in einem Servicevertrag erfassten Produkte oder Zusammensetzung zu
              ändern oder zu erweitern, wird er cloudbudies unverzüglich hiervon unterrichten. Sofern die Änderungen oder Erweiterungen zur Folge
              haben, dass Think Internet Operations den Service schwer oder unmöglich erbringen kann, ist Think Internet Operations nicht länger zur
              Erbringung der Serviceleistungen verpflichtet. In diesen Fällen wird Think Internet Operations – sofern technisch und betrieblich
              möglich – dem Kunden bezüglich des veränderten oder erweiterten Teil- oder Gesamtsystems ein Angebot unterbreiten. Einigen sich die
              Parteien im Hinblick auf die Serviceleistungen des veränderten oder erweiterten Teil- oder Gesamtsystems nicht, hat dies auf die vom
              Kunden zu zahlenden Servicegebühren keinen Einfluss.
            </P>
            <P>
              <Strong>§ 16 Servicezeiten</Strong>
              <br />
              1. Serviceleistungen erbringt Think Internet Operations montags bis freitags von 8:00 bis 17:00 Uhr mit Ausnahme von bundeseinheitlichen
              Feiertagen (folgend „Servicezeit“), sofern nichts anderes vereinbart ist.
              <br />
              2. Eventuell zugesagte Service-Level-Zeiten (z. B. Reaktionszeiten) gelten nur während der vereinbarten Servicezeit.
              Kundenanforderungen, die außerhalb der vereinbarten Servicezeit angenommen werden, werden so behandelt, als wären sie zu Beginn der
              nachfolgenden Servicezeiten eingegangen. Liegt das Ende der Service-Level-Zeit außerhalb der Servicezeit, wird die Service-Level-Zeit
              unterbrochen und läuft mit Beginn der nächsten Servicezeit weiter, sofern die Parteien nichts Abweichendes vereinbart haben.
              <br />
              3. Kann Think Internet Operations die Serviceleistungen während der vereinbarten Service-Level-Zeiten trotz aller Bemühungen nicht
              erbringen, ist Think Internet Operations berechtigt, innerhalb der vereinbarten Service-Level-Zeiten nach eigenem Ermessen statt den
              geschuldeten Serviceleistungen für eine Übergangsphase eine vertragliche Zwischenlösung zu erbringen.
            </P>
            <P>
              <Strong>§ 17 Schlussbestimmungen</Strong>
              <br />
              1. Erfüllungsort und ausschließlicher Gerichtsstand für alle Streitigkeiten aus oder im Zusammenhang mit dem Vertrag ist Memmingen.
              Think Internet Operations steht es jedoch frei, den Kunden vor einem anderen gesetzlich zuständigen Gericht in Anspruch zu nehmen.
              <br />
              2. Es gilt das Recht der Bundesrepublik Deutschland unter Ausschluss des UN-Übereinkommens über Verträge über den internationalen
              Warenkauf (CISG).
              <br />
              3. Eine Abtretung oder Übertragung von Rechten und/oder Pflichten durch den Kunden bedarf der vorherigen schriftlichen Zustimmung von
              Think Internet Operations.
              <br />
              4. Mündliche Abreden oder Zusagen, spätere Vertragsänderungen und -ergänzungen sowie Kündigungen, Rügen und Fristsetzungen des Kunden
              bedürfen zu ihrer Wirksamkeit der Schriftform. Dies gilt auch für einen Verzicht auf dieses
              <br />
              8 Version 1.1 – September 2019
              <br />
              Schriftformerfordernis.
              <br />
              5. Sollte eine Bestimmung dieser Geschäftsbedingungen ganz oder teilweise unwirksam sein oder werden, so wird dadurch die Wirksamkeit
              der übrigen Bestimmungen nicht berührt.
            </P>
          </Container>
        </Container>
      </StyledGTC>
    </Layout>
  )
}

export default GTC
