import { StyledCardCollection } from './CardCollection.Styled'
import Card from './Card/Card'

const CardCollection = ({ items }) => {
  return (
    <StyledCardCollection>
      {items.map((item, index) => {
        return <Card key={index} {...item} />
      })}
    </StyledCardCollection>
  )
}

export default CardCollection
