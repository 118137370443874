import { useSelector } from 'react-redux'
import props from '../../../../redux/props'
import Headline from '../../Headline/Headline'
import SmallContainer from '../../SmallContainer/SmallContainer'
import CardCollection from '../../CardCollection/CardCollection'
import { StyledTechnologies, Subline } from './Technologies.Styled'
import { Container } from '@think-internet/ui-components'

const Technologies = ({ translationRootKey }) => {
  const translation = useSelector((s) => s[props.TRANSLATION])

  return (
    <StyledTechnologies>
      <Container>
        <Headline text={translation[translationRootKey].technologies.headline} />
        <SmallContainer align={'left'}>
          <Subline>{translation[translationRootKey].technologies.subline}</Subline>
        </SmallContainer>
        <CardCollection items={translation[translationRootKey].technologies.items} />
      </Container>
    </StyledTechnologies>
  )
}

export default Technologies
