import styled from 'styled-components'

export const StyledWhoWeAre = styled.div`
  margin-top: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const Text = styled.div`
  text-align: center;
  margin-top: 40px;
`
