import { Container } from '@think-internet/ui-components'
import { useSelector } from 'react-redux'
import props from '../../redux/props'
import Layout from '../Layout/Layout'
import SubpageHero from '../SubComponents/SubpageHero/SubpageHero'
import { StyledDataProtection, Subheadline, P, Strong, UL, LI } from './DataProtection.Styled'

const DataProtection = () => {
  const translation = useSelector((s) => s[props.TRANSLATION])
  const lang = useSelector((s) => s[props.CURRENTLANG])

  return (
    <Layout authRequired={false}>
      <StyledDataProtection>
        <SubpageHero>{translation.dataProtection.headline}</SubpageHero>
        <Container>
          {lang === 'EN' && (
            <>
              <Subheadline>1. An overview of data protection</Subheadline>
              <Subheadline>General information</Subheadline>{' '}
              <P>
                The following information will provide you with an easy to navigate overview of what will happen with your personal data when you
                visit this website. The term “personal data” comprises all data that can be used to personally identify you. For detailed information
                about the subject matter of data protection, please consult our Data Protection Declaration, which we have included beneath this copy.
              </P>
              <Subheadline>Data recording on this website</Subheadline>{' '}
              <Subheadline>Who is the responsible party for the recording of data on this website (i.e., the “controller”)?</Subheadline>{' '}
              <P>
                The data on this website is processed by the operator of the website, whose contact information is available under section
                “Information about the responsible party (referred to as the “controller” in the GDPR)” in this Privacy Policy.
              </P>{' '}
              <Subheadline>How do we record your data?</Subheadline>{' '}
              <P>
                We collect your data as a result of your sharing of your data with us. This may, for instance be information you enter into our
                contact form.
              </P>{' '}
              <P>
                Other data shall be recorded by our IT systems automatically or after you consent to its recording during your website visit. This
                data comprises primarily technical information (e.g., web browser, operating system, or time the site was accessed). This information
                is recorded automatically when you access this website.
              </P>{' '}
              <Subheadline>What are the purposes we use your data for?</Subheadline>{' '}
              <P>
                A portion of the information is generated to guarantee the error free provision of the website. Other data may be used to analyze your
                user patterns.
              </P>{' '}
              <Subheadline>What rights do you have as far as your information is concerned?</Subheadline>{' '}
              <P>
                You have the right to receive information about the source, recipients, and purposes of your archived personal data at any time
                without having to pay a fee for such disclosures. You also have the right to demand that your data are rectified or eradicated. If you
                have consented to data processing, you have the option to revoke this consent at any time, which shall affect all future data
                processing. Moreover, you have the right to demand that the processing of your data be restricted under certain circumstances.
                Furthermore, you have the right to log a complaint with the competent supervising agency.
              </P>{' '}
              <P>Please do not hesitate to contact us at any time if you have questions about this or any other data protection related issues.</P>
              <Subheadline>Analysis tools and tools provided by third parties</Subheadline>{' '}
              <P>
                There is a possibility that your browsing patterns will be statistically analyzed when your visit this website. Such analyses are
                performed primarily with what we refer to as analysis programs.
              </P>{' '}
              <P>For detailed information about these analysis programs please consult our Data Protection Declaration below.</P>
              <Subheadline>2. Hosting and Content Delivery Networks (CDN)</Subheadline>
              <Subheadline>External Hosting</Subheadline>{' '}
              <P>
                This website is hosted by an external service provider (host). Personal data collected on this website are stored on the servers of
                the host. These may include, but are not limited to, IP addresses, contact requests, metadata and communications, contract
                information, contact information, names, web page access, and other data generated through a web site.
              </P>{' '}
              <P>
                The host is used for the purpose of fulfilling the contract with our potential and existing customers (Art. 6 para. 1 lit. b GDPR) and
                in the interest of secure, fast, and efficient provision of our online services by a professional provider (Art. 6 para. 1 lit. f
                GDPR).
              </P>{' '}
              <P>
                Our host will only process your data to the extent necessary to fulfil its performance obligations and to follow our instructions with
                respect to such data.
              </P>{' '}
              <P>We are using the following host:</P>
              <P>
                Amazon Web Services, Inc.
                <br />
                410 Terry Avenue North
                <br />
                Seattle WA 98109
                <br />
                United States
              </P>
              <Subheadline>3. General information and mandatory information</Subheadline>
              <Subheadline>Data protection</Subheadline>{' '}
              <P>
                The operators of this website and its pages take the protection of your personal data very seriously. Hence, we handle your personal
                data as confidential information and in compliance with the statutory data protection regulations and this Data Protection
                Declaration.
              </P>{' '}
              <P>
                Whenever you use this website, a variety of personal information will be collected. Personal data comprises data that can be used to
                personally identify you. This Data Protection Declaration explains which data we collect as well as the purposes we use this data for.
                It also explains how, and for which purpose the information is collected.
              </P>{' '}
              <P>
                We herewith advise you that the transmission of data via the Internet (i.e., through e-mail communications) may be prone to security
                gaps. It is not possible to completely protect data against third-party access.
              </P>
              <Subheadline>Information about the responsible party (referred to as the “controller” in the GDPR)</Subheadline>{' '}
              <P>The data processing controller on this website is:</P>{' '}
              <P>
                Think Internet Operations GmbH
                <br />
                Ulmenweg 2<br />
                87779 Trunkelsberg
              </P>
              <P>
                Phone: +491712181570
                <br />
                E-mail: homepage@think-internet.de
              </P>
              <P>
                The controller is the natural person or legal entity that single-handedly or jointly with others makes decisions as to the purposes of
                and resources for the processing of personal data (e.g., names, e-mail addresses, etc.).
              </P>
              <Subheadline>Storage duration</Subheadline>{' '}
              <P>
                Unless a more specific storage period has been specified in this privacy policy, your personal data will remain with us until the
                purpose for which it was collected no longer applies. If you assert a justified request for deletion or revoke your consent to data
                processing, your data will be deleted, unless we have other legally permissible reasons for storing your personal data (e.g., tax or
                commercial law retention periods); in the latter case, the deletion will take place after these reasons cease to apply.
              </P>
              <Subheadline>Information on data transfer to the USA</Subheadline>{' '}
              <P>
                Our website uses, in particular, tools from companies based in the USA. When these tools are active, your personal information may be
                transferred to the US servers of these companies. We must point out that the USA is not a safe third country within the meaning of EU
                data protection law. US companies are required to release personal data to security authorities without you as the data subject being
                able to take legal action against this. The possibility cannot therefore be excluded that US authorities (e.g. secret services) may
                process, evaluate, and permanently store your data on US servers for monitoring purposes.  We have no influence over these processing
                activities.
              </P>
              <Subheadline>Revocation of your consent to the processing of data</Subheadline>{' '}
              <P>
                A wide range of data processing transactions are possible only subject to your express consent. You can also revoke at any time any
                consent you have already given us. This shall be without prejudice to the lawfulness of any data collection that occurred prior to
                your revocation.
              </P>
              <Subheadline>
                Right to object to the collection of data in special cases; right to object to direct advertising (Art. 21 GDPR)
              </Subheadline>{' '}
              <P>
                IN THE EVENT THAT DATA ARE PROCESSED ON THE BASIS OF ART. 6 SECT. 1 LIT. E OR F GDPR, YOU HAVE THE RIGHT TO AT ANY TIME OBJECT TO THE
                PROCESSING OF YOUR PERSONAL DATA BASED ON GROUNDS ARISING FROM YOUR UNIQUE SITUATION. THIS ALSO APPLIES TO ANY PROFILING BASED ON
                THESE PROVISIONS. TO DETERMINE THE LEGAL BASIS, ON WHICH ANY PROCESSING OF DATA IS BASED, PLEASE CONSULT THIS DATA PROTECTION
                DECLARATION. IF YOU LOG AN OBJECTION, WE WILL NO LONGER PROCESS YOUR AFFECTED PERSONAL DATA, UNLESS WE ARE IN A POSITION TO PRESENT
                COMPELLING PROTECTION WORTHY GROUNDS FOR THE PROCESSING OF YOUR DATA, THAT OUTWEIGH YOUR INTERESTS, RIGHTS AND FREEDOMS OR IF THE
                PURPOSE OF THE PROCESSING IS THE CLAIMING, EXERCISING OR DEFENCE OF LEGAL ENTITLEMENTS (OBJECTION PURSUANT TO ART. 21 SECT. 1 GDPR).
              </P>{' '}
              <P>
                IF YOUR PERSONAL DATA IS BEING PROCESSED IN ORDER TO ENGAGE IN DIRECT ADVERTISING, YOU HAVE THE RIGHT TO AT ANY TIME OBJECT TO THE
                PROCESSING OF YOUR AFFECTED PERSONAL DATA FOR THE PURPOSES OF SUCH ADVERTISING. THIS ALSO APPLIES TO PROFILING TO THE EXTENT THAT IT
                IS AFFILIATED WITH SUCH DIRECT ADVERTISING. IF YOU OBJECT, YOUR PERSONAL DATA WILL SUBSEQUENTLY NO LONGER BE USED FOR DIRECT
                ADVERTISING PURPOSES (OBJECTION PURSUANT TO ART. 21 SECT. 2 GDPR).
              </P>
              <Subheadline>Right to log a complaint with the competent supervisory agency</Subheadline>{' '}
              <P>
                In the event of violations of the GDPR, data subjects are entitled to log a complaint with a supervisory agency, in particular in the
                member state where they usually maintain their domicile, place of work or at the place where the alleged violation occurred. The right
                to log a complaint is in effect regardless of any other administrative or court proceedings available as legal recourses.
              </P>
              <Subheadline>Right to data portability</Subheadline>{' '}
              <P>
                You have the right to demand that we hand over any data we automatically process on the basis of your consent or in order to fulfil a
                contract be handed over to you or a third party in a commonly used, machine readable format. If you should demand the direct transfer
                of the data to another controller, this will be done only if it is technically feasible.
              </P>
              <Subheadline>SSL and/or TLS encryption</Subheadline>{' '}
              <P>
                For security reasons and to protect the transmission of confidential content, such as purchase orders or inquiries you submit to us as
                the website operator, this website uses either an SSL or a TLS encryption program. You can recognize an encrypted connection by
                checking whether the address line of the browser switches from “http://” to “https://” and also by the appearance of the lock icon in
                the browser line.
              </P>{' '}
              <P>If the SSL or TLS encryption is activated, data you transmit to us cannot be read by third parties.</P>
              <Subheadline>Information about, rectification and eradication of data</Subheadline>{' '}
              <P>
                Within the scope of the applicable statutory provisions, you have the right to at any time demand information about your archived
                personal data, their source and recipients as well as the purpose of the processing of your data. You may also have a right to have
                your data rectified or eradicated. If you have questions about this subject matter or any other questions about personal data, please
                do not hesitate to contact us at any time.
              </P>
              <Subheadline>Right to demand processing restrictions</Subheadline>{' '}
              <P>
                You have the right to demand the imposition of restrictions as far as the processing of your personal data is concerned. To do so, you
                may contact us at any time. The right to demand restriction of processing applies in the following cases:
              </P>{' '}
              <UL>
                {' '}
                <LI>
                  In the event that you should dispute the correctness of your data archived by us, we will usually need some time to verify this
                  claim. During the time that this investigation is ongoing, you have the right to demand that we restrict the processing of your
                  personal data.
                </LI>{' '}
                <LI>
                  If the processing of your personal data was/is conducted in an unlawful manner, you have the option to demand the restriction of the
                  processing of your data in lieu of demanding the eradication of this data.
                </LI>{' '}
                <LI>
                  If we do not need your personal data any longer and you need it to exercise, defend or claim legal entitlements, you have the right
                  to demand the restriction of the processing of your personal data instead of its eradication.
                </LI>{' '}
                <LI>
                  If you have raised an objection pursuant to Art. 21 Sect. 1 GDPR, your rights and our rights will have to be weighed against each
                  other. As long as it has not been determined whose interests prevail, you have the right to demand a restriction of the processing
                  of your personal data.
                </LI>{' '}
              </UL>{' '}
              <P>
                If you have restricted the processing of your personal data, these data – with the exception of their archiving – may be processed
                only subject to your consent or to claim, exercise or defend legal entitlements or to protect the rights of other natural persons or
                legal entities or for important public interest reasons cited by the European Union or a member state of the EU.
              </P>
              <Subheadline>Rejection of unsolicited e-mails</Subheadline>{' '}
              <P>
                We herewith object to the use of contact information published in conjunction with the mandatory information to be provided in our
                Site Notice to send us promotional and information material that we have not expressly requested. The operators of this website and
                its pages reserve the express right to take legal action in the event of the unsolicited sending of promotional information, for
                instance via SPAM messages.
              </P>
              <Subheadline>4. Recording of data on this website</Subheadline>
              <Subheadline>Cookies</Subheadline>{' '}
              <P>
                Our websites and pages use what the industry refers to as “cookies.” Cookies are small text files that do not cause any damage to your
                device. They are either stored temporarily for the duration of a session (session cookies) or they are permanently archived on your
                device (permanent cookies). Session cookies are automatically deleted once you terminate your visit. Permanent cookies remain archived
                on your device until you actively delete them, or they are automatically eradicated by your web browser.
              </P>{' '}
              <P>
                In some cases, it is possible that third-party cookies are stored on your device once you enter our site (third-party cookies). These
                cookies enable you or us to take advantage of certain services offered by the third party (e.g., cookies for the processing of payment
                services).
              </P>{' '}
              <P>
                Cookies have a variety of functions. Many cookies are technically essential since certain website functions would not work in the
                absence of the cookies (e.g., the shopping cart function or the display of videos). The purpose of other cookies may be the analysis
                of user patterns or the display of promotional messages.
              </P>{' '}
              <P>
                Cookies, which are required for the performance of electronic communication transactions (required cookies) or for the provision of
                certain functions you want to use (functional cookies, e.g., for the shopping cart function) or those that are necessary for the
                optimization of the website (e.g., cookies that provide measurable insights into the web audience), shall be stored on the basis of
                Art. 6 Sect. 1 lit. f GDPR, unless a different legal basis is cited. The operator of the website has a legitimate interest in the
                storage of cookies to ensure the technically error free and optimized provision of the operator’s services. If your consent to the
                storage of the cookies has been requested, the respective cookies are stored exclusively on the basis of the consent obtained (Art. 6
                Sect. 1 lit. a GDPR); this consent may be revoked at any time.
              </P>{' '}
              <P>
                You have the option to set up your browser in such a manner that you will be notified any time cookies are placed and to permit the
                acceptance of cookies only in specific cases. You may also exclude the acceptance of cookies in certain cases or in general or
                activate the delete function for the automatic eradication of cookies when the browser closes. If cookies are deactivated, the
                functions of this website may be limited.
              </P>{' '}
              <P>
                In the event that third-party cookies are used or if cookies are used for analytical purposes, we will separately notify you in
                conjunction with this Data Protection Policy and, if applicable, ask for your consent.
              </P>
              <Subheadline>Server log files</Subheadline>{' '}
              <P>
                The provider of this website and its pages automatically collects and stores information in so-called server log files, which your
                browser communicates to us automatically. The information comprises:
              </P>{' '}
              <UL>
                {' '}
                <LI>The type and version of browser used</LI> <LI>The used operating system</LI> <LI>Referrer URL</LI>{' '}
                <LI>The hostname of the accessing computer</LI> <LI>The time of the server inquiry</LI> <LI>The IP address</LI>{' '}
              </UL>{' '}
              <P>This data is not merged with other data sources.</P>{' '}
              <P>
                This data is recorded on the basis of Art. 6 Sect. 1 lit. f GDPR. The operator of the website has a legitimate interest in the
                technically error free depiction and the optimization of the operator’s website. In order to achieve this, server log files must be
                recorded.
              </P>
              <Subheadline>Contact form</Subheadline>{' '}
              <P>
                If you submit inquiries to us via our contact form, the information provided in the contact form as well as any contact information
                provided therein will be stored by us in order to handle your inquiry and in the event that we have further questions. We will not
                share this information without your consent.
              </P>{' '}
              <P>
                The processing of these data is based on Art. 6 para. 1 lit. b GDPR, if your request is related to the execution of a contract or if
                it is necessary to carry out pre-contractual measures. In all other cases the processing is based on our legitimate interest in the
                effective processing of the requests addressed to us (Art. 6 Para. 1 lit. f GDPR) or on your agreement (Art. 6 Para. 1 lit. a GDPR) if
                this has been requested.
              </P>{' '}
              <P>
                The information you have entered into the contact form shall remain with us until you ask us to eradicate the data, revoke your
                consent to the archiving of data or if the purpose for which the information is being archived no longer exists (e.g., after we have
                concluded our response to your inquiry). This shall be without prejudice to any mandatory legal provisions – in particular retention
                periods.
              </P>
              <Subheadline>Request by e-mail, telephone, or fax</Subheadline>{' '}
              <P>
                If you contact us by e-mail, telephone or fax, your request, including all resulting personal data (name, request) will be stored and
                processed by us for the purpose of processing your request. We do not pass these data on without your consent.
              </P>{' '}
              <P>
                These data are processed on the basis of Art. 6 Sect. 1 lit. b GDPR if your inquiry is related to the fulfillment of a contract or is
                required for the performance of pre-contractual measures. In all other cases, the data are processed on the basis of our legitimate
                interest in the effective handling of inquiries submitted to us (Art. 6 Sect. 1 lit. f GDPR) or on the basis of your consent (Art. 6
                Sect. 1 lit. a GDPR) if it has been obtained.
              </P>{' '}
              <P>
                The data sent by you to us via contact requests remain with us until you request us to delete, revoke your consent to the storage or
                the purpose for the data storage lapses (e.g. after completion of your request). Mandatory statutory provisions - in particular
                statutory retention periods - remain unaffected.
              </P>
              <Subheadline>5. Newsletter</Subheadline>
              <Subheadline>Newsletter data</Subheadline>{' '}
              <P>
                If you would like to subscribe to the newsletter offered on this website, we will need from you an e-mail address as well as
                information that allow us to verify that you are the owner of the e-mail address provided and consent to the receipt of the
                newsletter. No further data shall be collected or shall be collected only on a voluntary basis. We shall use such data only for the
                sending of the requested information and shall not share such data with any third parties.
              </P>{' '}
              <P>
                The processing of the information entered into the newsletter subscription form shall occur exclusively on the basis of your consent
                (Art. 6 Sect. 1 lit. a GDPR). You may revoke the consent you have given to the archiving of data, the e-mail address and the use of
                this information for the sending of the newsletter at any time, for instance by clicking on the “Unsubscribe” link in the newsletter.
                This shall be without prejudice to the lawfulness of any data processing transactions that have taken place to date.
              </P>{' '}
              <P>
                The data deposited with us for the purpose of subscribing to the newsletter will be stored by us until you unsubscribe from the
                newsletter or the newsletter service provider and deleted from the newsletter distribution list after you unsubscribe from the
                newsletter or after the purpose has ceased to apply. We reserve the right to delete or block e-mail addresses from our newsletter
                distribution list at our own discretion within the scope of our legitimate interest in accordance with Art. 6(1)(f) GDPR.
              </P>{' '}
              <P>
                After you unsubscribe from the newsletter distribution list, your e-mail address may be stored by us or the newsletter service
                provider in a blacklist to prevent future mailings. The data from the blacklist is used only for this purpose and not merged with
                other data. This serves both your interest and our interest in complying with the legal requirements when sending newsletters
                (legitimate interest within the meaning of Art. 6 para. 1 lit. f GDPR). The storage in the blacklist is indefinite.{' '}
                <Strong>You may object to the storage if your interests outweigh our legitimate interest.</Strong>
              </P>
              <Subheadline>6. Plug-ins and Tools</Subheadline>
              <Subheadline>Google reCAPTCHA</Subheadline>{' '}
              <P>
                We use “Google reCAPTCHA” (hereinafter referred to as “reCAPTCHA”) on this website. The provider is Google Ireland Limited (“Google”),
                Gordon House, Barrow Street, Dublin 4, Ireland.
              </P>{' '}
              <P>
                The purpose of reCAPTCHA is to determine whether data entered on this website (e.g., information entered into a contact form) is being
                provided by a human user or by an automated program. To determine this, reCAPTCHA analyzes the behavior of the website visitors based
                on a variety of parameters. This analysis is triggered automatically as soon as the website visitor enters the site. For this
                analysis, reCAPTCHA evaluates a variety of data (e.g., IP address, time the website visitor spent on the site or cursor movements
                initiated by the user). The data tracked during such analyses are forwarded to Google.
              </P>{' '}
              <P>reCAPTCHA analyses run entirely in the background. Website visitors are not alerted that an analysis is underway.</P>{' '}
              <P>
                Data are stored and analyzed on the basis of Art. 6 Sect. 1 lit. f GDPR. The website operator has a legitimate interest in the
                protection of the operator’s websites against abusive automated spying and against SPAM. If a respective declaration of consent has
                been obtained, the data will be processed exclusively on the basis of Art. 6 Sect. 1 lit. a DGDPR. Any such consent may be revoked at
                any time.
              </P>{' '}
              <P>
                For more information about Google reCAPTCHA please refer to the Google Data Privacy Declaration and Terms Of Use under the following
                links:{' '}
                <a href="https://policies.google.com/privacy?hl=en" target="_blank" rel="noopener noreferrer">
                  https://policies.google.com/privacy?hl=en
                </a>{' '}
                and{' '}
                <a href="https://policies.google.com/terms?hl=en" target="_blank" rel="noopener noreferrer">
                  https://policies.google.com/terms?hl=en
                </a>
                .
              </P>
              <Subheadline>7. eCommerce and payment service providers</Subheadline>
              <Subheadline>Processing of data (customer and contract data)</Subheadline>{' '}
              <P>
                We collect, process, and use personal data only to the extent necessary for the establishment, content organization or change of the
                legal relationship (data inventory). These actions are taken on the basis of Art. 6 Sect. 1 lit. b GDPR, which permits the processing
                of data for the fulfilment of a contract or pre-contractual actions. We collect, process and use personal data concerning the use of
                this website (usage data) only to the extent that this is necessary to make it possible for users to utilize the services and to bill
                for them.
              </P>{' '}
              <P>
                The collected customer data shall be eradicated upon completion of the order or the termination of the business relationship. This
                shall be without prejudice to any statutory retention mandates.
              </P>
            </>
          )}
          {lang === 'DE' && (
            <>
              <Subheadline>1. Datenschutz auf einen Blick</Subheadline>
              <Subheadline>Allgemeine Hinweise</Subheadline>{' '}
              <P>
                Die folgenden Hinweise geben einen einfachen Überblick darüber, was mit Ihren personenbezogenen Daten passiert, wenn Sie diese Website
                besuchen. Personenbezogene Daten sind alle Daten, mit denen Sie persönlich identifiziert werden können. Ausführliche Informationen zum
                Thema Datenschutz entnehmen Sie unserer unter diesem Text aufgeführten Datenschutzerklärung.
              </P>
              <Subheadline>Datenerfassung auf dieser Website</Subheadline>{' '}
              <Subheadline>Wer ist verantwortlich für die Datenerfassung auf dieser Website?</Subheadline>{' '}
              <P>
                Die Datenverarbeitung auf dieser Website erfolgt durch den Websitebetreiber. Dessen Kontaktdaten können Sie dem Abschnitt „Hinweis zur
                Verantwortlichen Stelle“ in dieser Datenschutzerklärung entnehmen.
              </P>{' '}
              <Subheadline>Wie erfassen wir Ihre Daten?</Subheadline>{' '}
              <P>
                Ihre Daten werden zum einen dadurch erhoben, dass Sie uns diese mitteilen. Hierbei kann es sich z. B. um Daten handeln, die Sie in ein
                Kontaktformular eingeben.
              </P>{' '}
              <P>
                Andere Daten werden automatisch oder nach Ihrer Einwilligung beim Besuch der Website durch unsere IT-Systeme erfasst. Das sind vor
                allem technische Daten (z. B. Internetbrowser, Betriebssystem oder Uhrzeit des Seitenaufrufs). Die Erfassung dieser Daten erfolgt
                automatisch, sobald Sie diese Website betreten.
              </P>{' '}
              <Subheadline>Wofür nutzen wir Ihre Daten?</Subheadline>{' '}
              <P>
                Ein Teil der Daten wird erhoben, um eine fehlerfreie Bereitstellung der Website zu gewährleisten. Andere Daten können zur Analyse
                Ihres Nutzerverhaltens verwendet werden.
              </P>{' '}
              <Subheadline>Welche Rechte haben Sie bezüglich Ihrer Daten?</Subheadline>{' '}
              <P>
                Sie haben jederzeit das Recht, unentgeltlich Auskunft über Herkunft, Empfänger und Zweck Ihrer gespeicherten personenbezogenen Daten
                zu erhalten. Sie haben außerdem ein Recht, die Berichtigung oder Löschung dieser Daten zu verlangen. Wenn Sie eine Einwilligung zur
                Datenverarbeitung erteilt haben, können Sie diese Einwilligung jederzeit für die Zukunft widerrufen. Außerdem haben Sie das Recht,
                unter bestimmten Umständen die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen. Des Weiteren steht Ihnen ein
                Beschwerderecht bei der zuständigen Aufsichtsbehörde zu.
              </P>{' '}
              <P>Hierzu sowie zu weiteren Fragen zum Thema Datenschutz können Sie sich jederzeit an uns wenden.</P>
              <Subheadline>Analyse-Tools und Tools von Dritt­anbietern</Subheadline>{' '}
              <P>
                Beim Besuch dieser Website kann Ihr Surf-Verhalten statistisch ausgewertet werden. Das geschieht vor allem mit sogenannten
                Analyseprogrammen.
              </P>{' '}
              <P>Detaillierte Informationen zu diesen Analyseprogrammen finden Sie in der folgenden Datenschutzerklärung.</P>
              <Subheadline>2. Hosting und Content Delivery Networks (CDN)</Subheadline>
              <Subheadline>Externes Hosting</Subheadline>{' '}
              <P>
                Diese Website wird bei einem externen Dienstleister gehostet (Hoster). Die personenbezogenen Daten, die auf dieser Website erfasst
                werden, werden auf den Servern des Hosters gespeichert. Hierbei kann es sich v. a. um IP-Adressen, Kontaktanfragen, Meta- und
                Kommunikationsdaten, Vertragsdaten, Kontaktdaten, Namen, Websitezugriffe und sonstige Daten, die über eine Website generiert werden,
                handeln.
              </P>{' '}
              <P>
                Der Einsatz des Hosters erfolgt zum Zwecke der Vertragserfüllung gegenüber unseren potenziellen und bestehenden Kunden (Art. 6 Abs. 1
                lit. b DSGVO) und im Interesse einer sicheren, schnellen und effizienten Bereitstellung unseres Online-Angebots durch einen
                professionellen Anbieter (Art. 6 Abs. 1 lit. f DSGVO).
              </P>{' '}
              <P>
                Unser Hoster wird Ihre Daten nur insoweit verarbeiten, wie dies zur Erfüllung seiner Leistungspflichten erforderlich ist und unsere
                Weisungen in Bezug auf diese Daten befolgen.
              </P>{' '}
              <P>Wir setzen folgenden Hoster ein:</P>
              <P>
                Amazon Web Services, Inc.
                <br />
                410 Terry Avenue North
                <br />
                Seattle WA 98109
                <br />
                United States
              </P>
              <Subheadline>3. Allgemeine Hinweise und Pflicht­informationen</Subheadline>
              <Subheadline>Datenschutz</Subheadline>{' '}
              <P>
                Die Betreiber dieser Seiten nehmen den Schutz Ihrer persönlichen Daten sehr ernst. Wir behandeln Ihre personenbezogenen Daten
                vertraulich und entsprechend der gesetzlichen Datenschutzvorschriften sowie dieser Datenschutzerklärung.
              </P>{' '}
              <P>
                Wenn Sie diese Website benutzen, werden verschiedene personenbezogene Daten erhoben. Personenbezogene Daten sind Daten, mit denen Sie
                persönlich identifiziert werden können. Die vorliegende Datenschutzerklärung erläutert, welche Daten wir erheben und wofür wir sie
                nutzen. Sie erläutert auch, wie und zu welchem Zweck das geschieht.
              </P>{' '}
              <P>
                Wir weisen darauf hin, dass die Datenübertragung im Internet (z. B. bei der Kommunikation per E-Mail) Sicherheitslücken aufweisen
                kann. Ein lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht möglich.
              </P>
              <Subheadline>Hinweis zur verantwortlichen Stelle</Subheadline>{' '}
              <P>Die verantwortliche Stelle für die Datenverarbeitung auf dieser Website ist:</P>{' '}
              <P>
                Think Internet Operations GmbH
                <br />
                Ulmenweg 2<br />
                87779 Trunkelsberg
              </P>
              <P>
                Telefon: +491712181570
                <br />
                E-Mail: homepage@think-internet.de
              </P>
              <P>
                Verantwortliche Stelle ist die natürliche oder juristische Person, die allein oder gemeinsam mit anderen über die Zwecke und Mittel
                der Verarbeitung von personenbezogenen Daten (z. B. Namen, E-Mail-Adressen o. Ä.) entscheidet.
              </P>
              <Subheadline>Speicherdauer</Subheadline>{' '}
              <P>
                Soweit innerhalb dieser Datenschutzerklärung keine speziellere Speicherdauer genannt wurde, verbleiben Ihre personenbezogenen Daten
                bei uns, bis der Zweck für die Datenverarbeitung entfällt. Wenn Sie ein berechtigtes Löschersuchen geltend machen oder eine
                Einwilligung zur Datenverarbeitung widerrufen, werden Ihre Daten gelöscht, sofern wir keinen anderen rechtlich zulässigen  Gründe für
                die Speicherung Ihrer personenbezogenen Daten haben (z.B. steuer- oder handelsrechtliche Aufbewahrungsfristen); im letztgenannten Fall
                erfolgt die Löschung nach Fortfall dieser Gründe.
              </P>
              <Subheadline>Hinweis zur Datenweitergabe in die USA</Subheadline>{' '}
              <P>
                Auf unserer Website sind unter anderem Tools von Unternehmen mit Sitz in den USA eingebunden. Wenn diese Tools aktiv sind, können Ihre
                personenbezogenen Daten an die US-Server der jeweiligen Unternehmen weitergegeben werden. Wir weisen darauf hin, dass die USA kein
                sicherer Drittstaat im Sinne des EU-Datenschutzrechts sind. US-Unternehmen sind dazu verpflichtet, personenbezogene Daten an
                Sicherheitsbehörden herauszugeben, ohne dass Sie als Betroffener hiergegen gerichtlich vorgehen könnten. Es kann daher nicht
                ausgeschlossen werden, dass US-Behörden (z.B. Geheimdienste) Ihre auf US-Servern befindlichen Daten zu Überwachungszwecken
                verarbeiten, auswerten und dauerhaft speichern. Wir haben auf diese Verarbeitungstätigkeiten keinen Einfluss.
              </P>
              <Subheadline>Widerruf Ihrer Einwilligung zur Datenverarbeitung</Subheadline>{' '}
              <P>
                Viele Datenverarbeitungsvorgänge sind nur mit Ihrer ausdrücklichen Einwilligung möglich. Sie können eine bereits erteilte Einwilligung
                jederzeit widerrufen. Die Rechtmäßigkeit der bis zum Widerruf erfolgten Datenverarbeitung bleibt vom Widerruf unberührt.
              </P>
              <Subheadline>Widerspruchsrecht gegen die Datenerhebung in besonderen Fällen sowie gegen Direktwerbung (Art. 21 DSGVO)</Subheadline>{' '}
              <P>
                WENN DIE DATENVERARBEITUNG AUF GRUNDLAGE VON ART. 6 ABS. 1 LIT. E ODER F DSGVO ERFOLGT, HABEN SIE JEDERZEIT DAS RECHT, AUS GRÜNDEN,
                DIE SICH AUS IHRER BESONDEREN SITUATION ERGEBEN, GEGEN DIE VERARBEITUNG IHRER PERSONENBEZOGENEN DATEN WIDERSPRUCH EINZULEGEN; DIES
                GILT AUCH FÜR EIN AUF DIESE BESTIMMUNGEN GESTÜTZTES PROFILING. DIE JEWEILIGE RECHTSGRUNDLAGE, AUF DENEN EINE VERARBEITUNG BERUHT,
                ENTNEHMEN SIE DIESER DATENSCHUTZERKLÄRUNG. WENN SIE WIDERSPRUCH EINLEGEN, WERDEN WIR IHRE BETROFFENEN PERSONENBEZOGENEN DATEN NICHT
                MEHR VERARBEITEN, ES SEI DENN, WIR KÖNNEN ZWINGENDE SCHUTZWÜRDIGE GRÜNDE FÜR DIE VERARBEITUNG NACHWEISEN, DIE IHRE INTERESSEN, RECHTE
                UND FREIHEITEN ÜBERWIEGEN ODER DIE VERARBEITUNG DIENT DER GELTENDMACHUNG, AUSÜBUNG ODER VERTEIDIGUNG VON RECHTSANSPRÜCHEN (WIDERSPRUCH
                NACH ART. 21 ABS. 1 DSGVO).
              </P>{' '}
              <P>
                WERDEN IHRE PERSONENBEZOGENEN DATEN VERARBEITET, UM DIREKTWERBUNG ZU BETREIBEN, SO HABEN SIE DAS RECHT, JEDERZEIT WIDERSPRUCH GEGEN
                DIE VERARBEITUNG SIE BETREFFENDER PERSONENBEZOGENER DATEN ZUM ZWECKE DERARTIGER WERBUNG EINZULEGEN; DIES GILT AUCH FÜR DAS PROFILING,
                SOWEIT ES MIT SOLCHER DIREKTWERBUNG IN VERBINDUNG STEHT. WENN SIE WIDERSPRECHEN, WERDEN IHRE PERSONENBEZOGENEN DATEN ANSCHLIESSEND
                NICHT MEHR ZUM ZWECKE DER DIREKTWERBUNG VERWENDET (WIDERSPRUCH NACH ART. 21 ABS. 2 DSGVO).
              </P>
              <Subheadline>Beschwerde­recht bei der zuständigen Aufsichts­behörde</Subheadline>{' '}
              <P>
                Im Falle von Verstößen gegen die DSGVO steht den Betroffenen ein Beschwerderecht bei einer Aufsichtsbehörde, insbesondere in dem
                Mitgliedstaat ihres gewöhnlichen Aufenthalts, ihres Arbeitsplatzes oder des Orts des mutmaßlichen Verstoßes zu. Das Beschwerderecht
                besteht unbeschadet anderweitiger verwaltungsrechtlicher oder gerichtlicher Rechtsbehelfe.
              </P>
              <Subheadline>Recht auf Daten­übertrag­barkeit</Subheadline>{' '}
              <P>
                Sie haben das Recht, Daten, die wir auf Grundlage Ihrer Einwilligung oder in Erfüllung eines Vertrags automatisiert verarbeiten, an
                sich oder an einen Dritten in einem gängigen, maschinenlesbaren Format aushändigen zu lassen. Sofern Sie die direkte Übertragung der
                Daten an einen anderen Verantwortlichen verlangen, erfolgt dies nur, soweit es technisch machbar ist.
              </P>
              <Subheadline>SSL- bzw. TLS-Verschlüsselung</Subheadline>{' '}
              <P>
                Diese Seite nutzt aus Sicherheitsgründen und zum Schutz der Übertragung vertraulicher Inhalte, wie zum Beispiel Bestellungen oder
                Anfragen, die Sie an uns als Seitenbetreiber senden, eine SSL- bzw. TLS-Verschlüsselung. Eine verschlüsselte Verbindung erkennen Sie
                daran, dass die Adresszeile des Browsers von „http://“ auf „https://“ wechselt und an dem Schloss-Symbol in Ihrer Browserzeile.
              </P>{' '}
              <P>
                Wenn die SSL- bzw. TLS-Verschlüsselung aktiviert ist, können die Daten, die Sie an uns übermitteln, nicht von Dritten mitgelesen
                werden.
              </P>
              <Subheadline>Auskunft, Löschung und Berichtigung</Subheadline>{' '}
              <P>
                Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen jederzeit das Recht auf unentgeltliche Auskunft über Ihre gespeicherten
                personenbezogenen Daten, deren Herkunft und Empfänger und den Zweck der Datenverarbeitung und ggf. ein Recht auf Berichtigung oder
                Löschung dieser Daten. Hierzu sowie zu weiteren Fragen zum Thema personenbezogene Daten können Sie sich jederzeit an uns wenden.
              </P>
              <Subheadline>Recht auf Einschränkung der Verarbeitung</Subheadline>{' '}
              <P>
                Sie haben das Recht, die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen. Hierzu können Sie sich jederzeit
                an uns wenden. Das Recht auf Einschränkung der Verarbeitung besteht in folgenden Fällen:
              </P>{' '}
              <UL>
                {' '}
                <LI>
                  Wenn Sie die Richtigkeit Ihrer bei uns gespeicherten personenbezogenen Daten bestreiten, benötigen wir in der Regel Zeit, um dies zu
                  überprüfen. Für die Dauer der Prüfung haben Sie das Recht, die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu
                  verlangen.
                </LI>{' '}
                <LI>
                  Wenn die Verarbeitung Ihrer personenbezogenen Daten unrechtmäßig geschah/geschieht, können Sie statt der Löschung die Einschränkung
                  der Datenverarbeitung verlangen.
                </LI>{' '}
                <LI>
                  Wenn wir Ihre personenbezogenen Daten nicht mehr benötigen, Sie sie jedoch zur Ausübung, Verteidigung oder Geltendmachung von
                  Rechtsansprüchen benötigen, haben Sie das Recht, statt der Löschung die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten
                  zu verlangen.
                </LI>{' '}
                <LI>
                  Wenn Sie einen Widerspruch nach Art. 21 Abs. 1 DSGVO eingelegt haben, muss eine Abwägung zwischen Ihren und unseren Interessen
                  vorgenommen werden. Solange noch nicht feststeht, wessen Interessen überwiegen, haben Sie das Recht, die Einschränkung der
                  Verarbeitung Ihrer personenbezogenen Daten zu verlangen.
                </LI>{' '}
              </UL>{' '}
              <P>
                Wenn Sie die Verarbeitung Ihrer personenbezogenen Daten eingeschränkt haben, dürfen diese Daten – von ihrer Speicherung abgesehen –
                nur mit Ihrer Einwilligung oder zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen oder zum Schutz der Rechte einer
                anderen natürlichen oder juristischen Person oder aus Gründen eines wichtigen öffentlichen Interesses der Europäischen Union oder
                eines Mitgliedstaats verarbeitet werden.
              </P>
              <Subheadline>Widerspruch gegen Werbe-E-Mails</Subheadline>{' '}
              <P>
                Der Nutzung von im Rahmen der Impressumspflicht veröffentlichten Kontaktdaten zur Übersendung von nicht ausdrücklich angeforderter
                Werbung und Informationsmaterialien wird hiermit widersprochen. Die Betreiber der Seiten behalten sich ausdrücklich rechtliche
                Schritte im Falle der unverlangten Zusendung von Werbeinformationen, etwa durch Spam-E-Mails, vor.
              </P>
              <Subheadline>4. Datenerfassung auf dieser Website</Subheadline>
              <Subheadline>Cookies</Subheadline>{' '}
              <P>
                Unsere Internetseiten verwenden so genannte „Cookies“. Cookies sind kleine Textdateien und richten auf Ihrem Endgerät keinen Schaden
                an. Sie werden entweder vorübergehend für die Dauer einer Sitzung (Session-Cookies) oder dauerhaft (permanente Cookies) auf Ihrem
                Endgerät gespeichert. Session-Cookies werden nach Ende Ihres Besuchs automatisch gelöscht. Permanente Cookies bleiben auf Ihrem
                Endgerät gespeichert, bis Sie diese selbst löschen oder eine automatische Löschung durch Ihren Webbrowser erfolgt.
              </P>{' '}
              <P>
                Teilweise können auch Cookies von Drittunternehmen auf Ihrem Endgerät gespeichert werden, wenn Sie unsere Seite betreten
                (Third-Party-Cookies). Diese ermöglichen uns oder Ihnen die Nutzung bestimmter Dienstleistungen des Drittunternehmens (z.B. Cookies
                zur Abwicklung von Zahlungsdienstleistungen).
              </P>{' '}
              <P>
                Cookies haben verschiedene Funktionen. Zahlreiche Cookies sind technisch notwendig, da bestimmte Websitefunktionen ohne diese nicht
                funktionieren würden (z.B. die Warenkorbfunktion oder die Anzeige von Videos). Andere Cookies dienen dazu, das Nutzerverhalten
                auszuwerten oder Werbung anzuzeigen.
              </P>{' '}
              <P>
                Cookies, die zur Durchführung des elektronischen Kommunikationsvorgangs (notwendige Cookies) oder zur Bereitstellung bestimmter, von
                Ihnen erwünschter Funktionen (funktionale Cookies, z. B. für die Warenkorbfunktion) oder zur Optimierung der Website (z.B. Cookies zur
                Messung des Webpublikums) erforderlich sind, werden auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO gespeichert, sofern keine andere
                Rechtsgrundlage angegeben wird. Der Websitebetreiber hat ein berechtigtes Interesse an der Speicherung von Cookies zur technisch
                fehlerfreien und optimierten Bereitstellung seiner Dienste. Sofern eine Einwilligung zur Speicherung von Cookies abgefragt wurde,
                erfolgt die Speicherung der betreffenden Cookies ausschließlich auf Grundlage dieser Einwilligung (Art. 6 Abs. 1 lit. a DSGVO); die
                Einwilligung ist jederzeit widerrufbar.
              </P>{' '}
              <P>
                Sie können Ihren Browser so einstellen, dass Sie über das Setzen von Cookies informiert werden und Cookies nur im Einzelfall erlauben,
                die Annahme von Cookies für bestimmte Fälle oder generell ausschließen sowie das automatische Löschen der Cookies beim Schließen des
                Browsers aktivieren. Bei der Deaktivierung von Cookies kann die Funktionalität dieser Website eingeschränkt sein.
              </P>{' '}
              <P>
                Soweit Cookies von Drittunternehmen oder zu Analysezwecken eingesetzt werden, werden wir Sie hierüber im Rahmen dieser
                Datenschutzerklärung gesondert informieren und ggf. eine Einwilligung abfragen.
              </P>
              <Subheadline>Server-Log-Dateien</Subheadline>{' '}
              <P>
                Der Provider der Seiten erhebt und speichert automatisch Informationen in so genannten Server-Log-Dateien, die Ihr Browser automatisch
                an uns übermittelt. Dies sind:
              </P>{' '}
              <UL>
                {' '}
                <LI>Browsertyp und Browserversion</LI> <LI>verwendetes Betriebssystem</LI> <LI>Referrer URL</LI>{' '}
                <LI>Hostname des zugreifenden Rechners</LI> <LI>Uhrzeit der Serveranfrage</LI> <LI>IP-Adresse</LI>{' '}
              </UL>{' '}
              <P>Eine Zusammenführung dieser Daten mit anderen Datenquellen wird nicht vorgenommen.</P>{' '}
              <P>
                Die Erfassung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Der Websitebetreiber hat ein berechtigtes Interesse
                an der technisch fehlerfreien Darstellung und der Optimierung seiner Website – hierzu müssen die Server-Log-Files erfasst werden.
              </P>
              <Subheadline>Kontaktformular</Subheadline>{' '}
              <P>
                Wenn Sie uns per Kontaktformular Anfragen zukommen lassen, werden Ihre Angaben aus dem Anfrageformular inklusive der von Ihnen dort
                angegebenen Kontaktdaten zwecks Bearbeitung der Anfrage und für den Fall von Anschlussfragen bei uns gespeichert. Diese Daten geben
                wir nicht ohne Ihre Einwilligung weiter.
              </P>{' '}
              <P>
                Die Verarbeitung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1 lit. b DSGVO, sofern Ihre Anfrage mit der Erfüllung eines
                Vertrags zusammenhängt oder zur Durchführung vorvertraglicher Maßnahmen erforderlich ist. In allen übrigen Fällen beruht die
                Verarbeitung auf unserem berechtigten Interesse an der effektiven Bearbeitung der an uns gerichteten Anfragen (Art. 6 Abs. 1 lit. f
                DSGVO) oder auf Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO) sofern diese abgefragt wurde.
              </P>{' '}
              <P>
                Die von Ihnen im Kontaktformular eingegebenen Daten verbleiben bei uns, bis Sie uns zur Löschung auffordern, Ihre Einwilligung zur
                Speicherung widerrufen oder der Zweck für die Datenspeicherung entfällt (z. B. nach abgeschlossener Bearbeitung Ihrer Anfrage).
                Zwingende gesetzliche Bestimmungen – insbesondere Aufbewahrungsfristen – bleiben unberührt.
              </P>
              <Subheadline>Anfrage per E-Mail, Telefon oder Telefax</Subheadline>{' '}
              <P>
                Wenn Sie uns per E-Mail, Telefon oder Telefax kontaktieren, wird Ihre Anfrage inklusive aller daraus hervorgehenden personenbezogenen
                Daten (Name, Anfrage) zum Zwecke der Bearbeitung Ihres Anliegens bei uns gespeichert und verarbeitet. Diese Daten geben wir nicht ohne
                Ihre Einwilligung weiter.
              </P>{' '}
              <P>
                Die Verarbeitung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1 lit. b DSGVO, sofern Ihre Anfrage mit der Erfüllung eines
                Vertrags zusammenhängt oder zur Durchführung vorvertraglicher Maßnahmen erforderlich ist. In allen übrigen Fällen beruht die
                Verarbeitung auf unserem berechtigten Interesse an der effektiven Bearbeitung der an uns gerichteten Anfragen (Art. 6 Abs. 1 lit. f
                DSGVO) oder auf Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO) sofern diese abgefragt wurde.
              </P>{' '}
              <P>
                Die von Ihnen an uns per Kontaktanfragen übersandten Daten verbleiben bei uns, bis Sie uns zur Löschung auffordern, Ihre Einwilligung
                zur Speicherung widerrufen oder der Zweck für die Datenspeicherung entfällt (z. B. nach abgeschlossener Bearbeitung Ihres Anliegens).
                Zwingende gesetzliche Bestimmungen – insbesondere gesetzliche Aufbewahrungsfristen – bleiben unberührt.
              </P>
              <Subheadline>5. Newsletter</Subheadline>
              <Subheadline>Newsletter­daten</Subheadline>{' '}
              <P>
                Wenn Sie den auf der Website angebotenen Newsletter beziehen möchten, benötigen wir von Ihnen eine E-Mail-Adresse sowie Informationen,
                welche uns die Überprüfung gestatten, dass Sie der Inhaber der angegebenen E-Mail-Adresse sind und mit dem Empfang des Newsletters
                einverstanden sind. Weitere Daten werden nicht bzw. nur auf freiwilliger Basis erhoben. Diese Daten verwenden wir ausschließlich für
                den Versand der angeforderten Informationen und geben diese nicht an Dritte weiter.
              </P>{' '}
              <P>
                Die Verarbeitung der in das Newsletteranmeldeformular eingegebenen Daten erfolgt ausschließlich auf Grundlage Ihrer Einwilligung (Art.
                6 Abs. 1 lit. a DSGVO). Die erteilte Einwilligung zur Speicherung der Daten, der E-Mail-Adresse sowie deren Nutzung zum Versand des
                Newsletters können Sie jederzeit widerrufen, etwa über den „Austragen“-Link im Newsletter. Die Rechtmäßigkeit der bereits erfolgten
                Datenverarbeitungsvorgänge bleibt vom Widerruf unberührt.
              </P>{' '}
              <P>
                Die von Ihnen zum Zwecke des Newsletter-Bezugs bei uns hinterlegten Daten werden von uns bis zu Ihrer Austragung aus dem Newsletter
                bei uns bzw. dem Newsletterdiensteanbieter gespeichert und nach der Abbestellung des Newsletters oder nach Zweckfortfall aus der
                Newsletterverteilerliste gelöscht. Wir behalten uns vor, E-Mail-Adressen aus unserem Newsletterverteiler nach eigenem Ermessen im
                Rahmen unseres berechtigten Interesses nach Art. 6 Abs. 1 lit. f DSGVO zu löschen oder zu sperren.
              </P>{' '}
              <P>
                Nach Ihrer Austragung aus der Newsletterverteilerliste wird Ihre E-Mail-Adresse bei uns bzw. dem Newsletterdiensteanbieter ggf. in
                einer Blacklist gespeichert, um künftige Mailings zu verhindern. Die Daten aus der Blacklist werden nur für diesen Zweck verwendet und
                nicht mit anderen Daten zusammengeführt. Dies dient sowohl Ihrem Interesse als auch unserem Interesse an der Einhaltung der
                gesetzlichen Vorgaben beim Versand von Newslettern (berechtigtes Interesse im Sinne des Art. 6 Abs. 1 lit. f DSGVO). Die Speicherung
                in der Blacklist ist zeitlich nicht befristet.{' '}
                <Strong>Sie können der Speicherung widersprechen, sofern Ihre Interessen unser berechtigtes Interesse überwiegen.</Strong>
              </P>
              <Subheadline>6. Plugins und Tools</Subheadline>
              <Subheadline>Google reCAPTCHA</Subheadline>{' '}
              <P>
                Wir nutzen „Google reCAPTCHA“ (im Folgenden „reCAPTCHA“) auf dieser Website. Anbieter ist die Google Ireland Limited („Google“),
                Gordon House, Barrow Street, Dublin 4, Irland.
              </P>{' '}
              <P>
                Mit reCAPTCHA soll überprüft werden, ob die Dateneingabe auf dieser Website (z. B. in einem Kontaktformular) durch einen Menschen oder
                durch ein automatisiertes Programm erfolgt. Hierzu analysiert reCAPTCHA das Verhalten des Websitebesuchers anhand verschiedener
                Merkmale. Diese Analyse beginnt automatisch, sobald der Websitebesucher die Website betritt. Zur Analyse wertet reCAPTCHA verschiedene
                Informationen aus (z. B. IP-Adresse, Verweildauer des Websitebesuchers auf der Website oder vom Nutzer getätigte Mausbewegungen). Die
                bei der Analyse erfassten Daten werden an Google weitergeleitet.
              </P>{' '}
              <P>
                Die reCAPTCHA-Analysen laufen vollständig im Hintergrund. Websitebesucher werden nicht darauf hingewiesen, dass eine Analyse
                stattfindet.
              </P>{' '}
              <P>
                Die Speicherung und Analyse der Daten erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Der Websitebetreiber hat ein berechtigtes
                Interesse daran, seine Webangebote vor missbräuchlicher automatisierter Ausspähung und vor SPAM zu schützen. Sofern eine entsprechende
                Einwilligung abgefragt wurde, erfolgt die Verarbeitung ausschließlich auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO; die Einwilligung
                ist jederzeit widerrufbar.
              </P>{' '}
              <P>
                Weitere Informationen zu Google reCAPTCHA entnehmen Sie den Google-Datenschutzbestimmungen und den Google Nutzungsbedingungen unter
                folgenden Links:{' '}
                <a href="https://policies.google.com/privacy?hl=de" target="_blank" rel="noopener noreferrer">
                  https://policies.google.com/privacy?hl=de
                </a>{' '}
                und{' '}
                <a href="https://policies.google.com/terms?hl=de" target="_blank" rel="noopener noreferrer">
                  https://policies.google.com/terms?hl=de
                </a>
                .
              </P>
              <Subheadline>7. eCommerce und Zahlungs­anbieter</Subheadline>
              <Subheadline>Verarbeiten von Daten (Kunden- und Vertragsdaten)</Subheadline>{' '}
              <P>
                Wir erheben, verarbeiten und nutzen personenbezogene Daten nur, soweit sie für die Begründung, inhaltliche Ausgestaltung oder Änderung
                des Rechtsverhältnisses erforderlich sind (Bestandsdaten). Dies erfolgt auf Grundlage von Art. 6 Abs. 1 lit. b DSGVO, der die
                Verarbeitung von Daten zur Erfüllung eines Vertrags oder vorvertraglicher Maßnahmen gestattet. Personenbezogene Daten über die
                Inanspruchnahme dieser Website (Nutzungsdaten) erheben, verarbeiten und nutzen wir nur, soweit dies erforderlich ist, um dem Nutzer
                die Inanspruchnahme des Dienstes zu ermöglichen oder abzurechnen.
              </P>{' '}
              <P>
                Die erhobenen Kundendaten werden nach Abschluss des Auftrags oder Beendigung der Geschäftsbeziehung gelöscht. Gesetzliche
                Aufbewahrungsfristen bleiben unberührt.
              </P>
            </>
          )}
        </Container>
      </StyledDataProtection>
    </Layout>
  )
}

export default DataProtection
