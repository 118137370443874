import styled from 'styled-components'
import bg from '../../../../assets/image/generic/topicSubpage/technologies/bg.png'

export const StyledTechnologies = styled.div`
  margin-top: 100px;
  padding: 100px 0;
  background-image: url(${bg});
  background-size: 100% 100%;
  background-repeat: no-repeat;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    background-size: 100% auto;
    margin-top: 50px;
    padding: 50px 0;
  }
`

export const Subline = styled.div`
  font-size: ${({ theme }) => theme.font.size.sm};
  margin-top: 15px;
`
