import styled from 'styled-components'

export const StyledHeadline = styled.h2`
  font-family: 'bold';
  color: ${({ theme, color }) => (color ? theme.color.font[color] : theme.color.font.headline)};
  font-size: ${({ theme }) => theme.font.size.headline};
  text-transform: uppercase;
  text-align: ${({ align }) => align || 'left'};
  font-style: italic;
  ${({ width }) => (width ? `width: ${width}` : '')};
  @media (max-width: ${({ theme }) => theme.breakpoint.lg}) {
    font-size: ${({ theme }) => theme.font.size.xlg};
  }
`
