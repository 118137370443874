import styled from 'styled-components'

export const StyledSequence = styled.div`
  margin-top: 100px;
`

export const Subline = styled.div`
  text-align: center;
  font-size: ${({ theme }) => theme.font.size.sm};
  margin-top: 15px;
`
