import styled from 'styled-components'

export const StyledReferences = styled.div`
  margin-top: 200px;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    padding-bottom: 100px;
  }
`

export const Item = styled.div`
  background-color: ${({ theme }) => theme.color.background.primary};
  -webkit-box-shadow: 10px 10px 38px -7px rgba(142, 158, 248, 1);
  -moz-box-shadow: 10px 10px 38px -7px rgba(142, 158, 248, 1);
  box-shadow: 10px 10px 38px -7px rgba(142, 158, 248, 1);
  height: 100%;
`

export const Content = styled.div`
  display: flex;
  align-items: center;
  padding: 25px;
  height: 100%;
  width: 100%;
  gap: 15px;
  box-sizing: border-box;
  -webkit-box-shadow: -10px -10px 38px -7px rgba(255, 255, 255, 1);
  -moz-box-shadow: -10px -10px 38px -7px rgba(255, 255, 255, 1);
  box-shadow: -10px -10px 38px -7px rgba(255, 255, 255, 1);
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    flex-direction: column;
  }
`

export const ImageWrapper = styled.div`
  width: 150px;
  position: relative;
  flex-shrink: 0;
`

export const Image = styled.img`
  max-width: 90%;
  max-height: 200px;
`
export const TextWrapper = styled.div`
  text-align: left;
`

export const Title = styled.div`
  font-family: 'bold';
  text-transform: uppercase;
  font-size: ${({ theme }) => theme.font.size.lg};
`
export const Text = styled.div`
  margin-top: 15px;
  font-size: ${({ theme }) => theme.font.size.sm};
`

export const CarouselWrapper = styled.div`
  margin-top: 50px;
`
