import styled from 'styled-components'

export const StyledItem = styled.div`
  width: calc(50% - 50px);
  cursor: pointer;

  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    width: 100%;
  }
`
export const Box = styled.div`
  margin-top: 20px;
  background-color: ${({ theme }) => theme.color.background.white};
  padding: 20px;
  border-radius: ${({ theme }) => theme.border.radius};
  box-shadow: ${({ theme }) => theme.shadow};
`
export const Headline = styled.div`
  font-size: ${({ theme }) => theme.font.size.lg};
  font-family: bold;
`
export const Text = styled.div`
  margin-top: 10px;
  font-size: ${({ theme }) => theme.font.size.lg};
`
export const SubHeadline = styled.div`
  text-transform: uppercase;
  font-size: ${({ theme }) => theme.font.size.lg};
  text-align: center;
`
