import Layout from '../Layout/Layout'
import { StyledContact } from './Contact.Styled.js'
import ContactForm from '../SubComponents/ContactForm/ContactForm'

const Contact = () => {
  return (
    <Layout authRequired={false}>
      <StyledContact>
        <ContactForm />
      </StyledContact>
    </Layout>
  )
}

export default Contact
