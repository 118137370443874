import { StyledCard, Content, Name, Text, Point } from './Card.Styled'
import pointImage from '../../../../assets/image/generic/cardCollection/point.svg'

const Card = ({ name, text }) => {
  return (
    <StyledCard>
      <Content>
        <Point src={pointImage} />
        <Name>{name}</Name>
        <Text>{text}</Text>
      </Content>
    </StyledCard>
  )
}

export default Card
