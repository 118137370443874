import styled from 'styled-components'

export const StyledNewsView = styled.div`
  padding: 150px 0;
`

export const Items = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  margin-top: 5px;
`
