import styled from 'styled-components'
import bg from '../../../assets/image/footer/bg.png'

export const StyledFooter = styled.div`
  margin-top: 200px;
  background-color: ${({ theme }) => theme.color.background.primary};
  background-image: url(${bg});
  background-size: cover;
  padding: 115px 0 50px 100px;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    padding: 100px 0 15px 0;
    background-size: auto 100%;
    margin-top: 100px;
  }
`

export const Column = styled.div`
  flex: 1 1 0px;
  margin: ${({ margin }) => margin || '0px'};
  display: flex;
  flex-direction: column;
  gap: 10px;
`

export const CustomLink = styled.a`
  font-size: ${({ theme }) => theme.font.size.md};
  text-decoration: none;
  color: ${({ theme }) => theme.color.font.primary};
`

export const CustomText = styled.div`
  font-size: ${({ theme }) => theme.font.size.sm};
  color: ${({ theme }) => theme.color.font.secondary};
`

export const Flex = styled.div`
  display: flex;
  gap: 25px;
  align-items: flex-start;
  justify-content: ${({ justify }) => justify || 'center'};
  width: 100%;
  margin: ${({ margin }) => margin || '0px'};

  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    flex-direction: column;
    padding-top: 60px;
    padding-bottom: 30px;
    gap: 40px;
  }
`

export const Text = styled.div`
  font-size: ${({ theme }) => theme.font.size.sm};
`

export const ImageWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`

export const Logo = styled.img`
  width: 150px;
`

export const BVMWLogo = styled.img`
  height: 50px;
`
