import TopicSubpage from '../SubComponents/TopicSubpage/TopicSubpage'
import development from '../../assets/image/software/sequence/development.svg'
import analysis from '../../assets/image/software/sequence/analysis.svg'
import concept from '../../assets/image/software/sequence/concept.svg'
import support from '../../assets/image/software/sequence/support.svg'

const Software = () => {
  const sequenceImages = [analysis, concept, development, support]
  return <TopicSubpage translationRootKey={'software'} sequenceImages={sequenceImages} />
}

export default Software
