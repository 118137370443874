import styled from 'styled-components'

export const StyledTimeline = styled.div``

export const ItemsWrapper = styled.div`
  position: relative;
`

export const Items = styled.div`
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  position: relative;
  gap: 100px;
  padding: 50px 0;
  @media (max-width: ${({ theme }) => theme.breakpoint.sm}) {
    align-items: center;
    margin-top: 50px;
  }
`

export const Item = styled.div`
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: ${({ reverse }) => (reverse ? 'row-reverse' : 'row')};
  justify-content: center;
  @media (max-width: ${({ theme }) => theme.breakpoint.sm}) {
    flex-direction: column-reverse;
  }
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: ${({ reverse }) => (reverse ? 'flex-start' : 'flex-end')};
  width: 300px;
  @media (max-width: ${({ theme }) => theme.breakpoint.sm}) {
    align-items: center;
  }
`
export const Name = styled.div`
  text-transform: uppercase;
  font-family: 'bold';
  font-size: ${({ theme }) => theme.font.size.xlg};
  @media (max-width: ${({ theme }) => theme.breakpoint.lg}) {
    font-size: ${({ theme }) => theme.font.size.lg};
  }
`
export const Text = styled.div`
  margin-top: 10px;
  text-align: ${({ reverse }) => (reverse ? 'left' : 'right')};
`

export const CTA = styled.a`
  margin-top: 25px;
  width: fit-content;
  text-transform: uppercase;
  font-family: 'bold';
  background: rgb(116, 250, 253);
  background: linear-gradient(90deg, rgba(116, 250, 253, 1) 0%, rgba(141, 159, 248, 1) 100%);
  ${({ reverse, theme }) => !reverse && `background: ${theme.color.background.white};`}
  padding: 7.5px 25px;
  text-decoration: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  &:hover {
    transform: scale(1.05);
  }
`

export const Knob = styled.img`
  height: 75px;
  margin-top: -20px;
  @media (max-width: ${({ theme }) => theme.breakpoint.sm}) {
    margin-top: 0px;
  }
`

export const ImageWrapper = styled.div`
  text-align: ${({ reverse }) => (reverse ? 'left' : 'right')};
  height: 250px;
  width: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.color.background.white};
  border-radius: 30px; ;
`

export const Image = styled.img`
  max-width: 90%;
  max-height: 90%;
`

export const Line = styled.div`
  height: 100%;
  width: 3px;
  position: absolute;
  left: 50%;
  transform: translateX(-3px);
  background: ${({ theme }) => theme.color.background.white};
  top: 0px;
`

export const Border = styled.div`
  height: 3px;
  width: 50%;
  ${({ position }) => (position === 'top' ? `top: 0px;` : `bottom: 0px;`)}
  ${({ position }) => (position === 'top' ? `right: 0px;` : `left: 0px;`)}
  background: ${({ theme }) => theme.color.background.white};
  position: absolute;
`
