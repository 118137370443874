import styled from 'styled-components'

export const StyledImprint = styled.div``

export const Subheadline = styled.div`
  font-size: ${({ theme }) => theme.font.size.lg};
  margin-top: 25px;
`
export const P = styled.div`
  margin-top: 25px;
`

export const Strong = styled.span`
  font-family: 'bold';
`
