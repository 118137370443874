import { Text, Headline, Wrapper } from './Item.Styled.js'

const Item = ({ item, image, odd }) => {
  return (
    <Wrapper odd={odd}>
      <Headline>{item.name}</Headline>
      <Text>{item.text}</Text>
    </Wrapper>
  )
}

export default Item
