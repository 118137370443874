import { Container } from '@think-internet/ui-components'
import { useSelector } from 'react-redux'
import props from '../../../redux/props'
import Headline from '../../SubComponents/Headline/Headline'
import { StyledPerfection, Subline, Points, Point, Content, Icon, Title, Text } from './Perfection.Styled'
import colorIcon from '../../../assets/image/home/perfection/color.svg'
import blackIcon from '../../../assets/image/home/perfection/black.svg'

const Perfection = () => {
  const translation = useSelector((s) => s[props.TRANSLATION])
  const icons = [blackIcon, colorIcon, blackIcon]

  return (
    <StyledPerfection>
      <Container>
        <Headline text={translation.generic.motto} />
        <Subline>{translation.home.perfection.subline}</Subline>
        <Points>
          {translation.home.perfection.points.map((p, i) => (
            <Point key={i}>
              <Content>
                <Icon src={icons[i]} />
                <Title>{p.title}</Title>
                <Text>{p.text}</Text>
              </Content>
            </Point>
          ))}
        </Points>
      </Container>
    </StyledPerfection>
  )
}

export default Perfection
