import { StyledCarousel, Indicators, Indicator } from './Carousel.Styled'
import { useState } from 'react'
import Slide from './Slide/Slide'
import { useEffect } from 'react'
import { useCallback } from 'react'

const Carousel = ({ items }) => {
  const [index, setIndex] = useState(0)

  const getPrevIndex = () => {
    if (index === 0) {
      return items.length - 1
    }
    return index - 1
  }

  const getNextIndex = useCallback(() => {
    if (index === items.length - 1) {
      return 0
    }
    return index + 1
  }, [index, items.length])

  useEffect(() => {
    const interval = setInterval(() => {
      setIndex(getNextIndex())
    }, 5000)
    return () => clearInterval(interval)
  }, [index, getNextIndex])

  return (
    <StyledCarousel>
      <Slide mode="left" onClick={() => setIndex(getPrevIndex())}>
        {items[getPrevIndex()]}
      </Slide>
      <Slide mode="center">{items[index]}</Slide>
      <Slide mode="right" onClick={() => setIndex(getNextIndex())}>
        {items[getNextIndex()]}
      </Slide>
      <Indicators>
        {items.map((item, i) => (
          <Indicator
            key={i}
            active={i === index}
            onClick={() => {
              setIndex(i)
            }}
          />
        ))}
      </Indicators>
    </StyledCarousel>
  )
}

export default Carousel
