import { useSelector } from 'react-redux'
import props from '../../../redux/props'
import { StyledMottoBlock, Content, CTA, CustomHeadline } from './MottoBlock.Styled'

const MottoBlock = () => {
  const translation = useSelector((s) => s[props.TRANSLATION])
  return (
    <StyledMottoBlock>
      <Content>
        <CustomHeadline text={translation.generic.motto} />
        <CTA href="/#contact">{translation.mottoBlock.cta}</CTA>
      </Content>
    </StyledMottoBlock>
  )
}

export default MottoBlock
