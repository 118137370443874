import styled from 'styled-components'

export const StyledSlide = styled.div`
  position: absolute;
  text-align: center;
  width: 500px;
  height: fit-content;
  display: flex;
  align-items: center;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  ${({ mode }) => {
    switch (mode) {
      case 'left':
        return `
          left: 0;
          cursor: pointer;
          opacity: 0.5;
        `
      case 'center':
        return `
          left: 50%;
          transform: translate(-50%, -50%);
          z-index: 2;
          height: 80%;
        `
      case 'right':
        return `
          right: 0;
          cursor: pointer;
          opacity: 0.5;
        `
      default:
        return ''
    }
  }}

  @media (max-width: ${({ theme }) => theme.breakpoint.lg}) {
    max-width: 600px;
    height: 350px;
    margin-left: auto;
    margin-right: auto;
  }
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    ${({ mode }) => {
      switch (mode) {
        case 'left':
          return `
          display: none;
          `
        case 'center':
          return `
          width: 100%;
          height: fit-content;
          position: static;
          top: 0;
          transform: translateY(0);
          `
        case 'right':
          return `
            display: none;
        `
        default:
          return ''
      }
    }}
  }
`
