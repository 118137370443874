import { StyledSlide } from './Slide.Styled'

const Slide = ({ children, mode, onClick }) => {
  return (
    <StyledSlide onClick={onClick} mode={mode}>
      {children}
    </StyledSlide>
  )
}

export default Slide
