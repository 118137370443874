import { StyledSmallContainer } from './SmallContainer.Styled'

const SmallContainer = ({ children, className, align = 'center' }) => {
  return (
    <StyledSmallContainer align={align} className={className}>
      {children}
    </StyledSmallContainer>
  )
}

export default SmallContainer
