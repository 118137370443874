import styled from 'styled-components'
import defaultBG from '../../assets/image/generic/subpageHero/defaultBG.png'
import bg from '../../assets/image/generic/topicSubpage/intro/bg.png'

export const StyledPage = styled.div`
  background-image: url(${bg});
  background-size: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  padding-bottom: 100px;
  padding-top: 150px;
`

export const Headline = styled.div`
  background-image: url(${defaultBG});
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 200px 0px;
  color: ${({ theme }) => theme.color.font.white};
  font-size: ${({ theme }) => theme.font.size.headline};
  font-style: italic;
  font-family: 'bold';
  text-transform: uppercase;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    font-size: ${({ theme }) => theme.font.size.xlg};
    padding: 100px 0px;
  }
  @media (max-width: ${({ theme }) => theme.breakpoint.sm}) {
    width: 100%;
    font-size: ${({ theme }) => theme.font.size.lg};
    background-size: auto 100%;
  }
`

export const HeroWrapper = styled.div``

export const Section = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 50px;
  margin-top: 50px;
  justify-content: ${({ center }) => (center ? 'center' : 'flex-start')};
  width: 100%;

  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    flex-direction: column;
  }
`

export const SubHeadline = styled.div`
  font-size: ${({ theme }) => theme.font.size.xlg};
  font-family: bold;
  margin-top: 100px;
  text-transform: uppercase;

  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    font-size: ${({ theme }) => theme.font.size.lg};
  }
`

export const CenterSection = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  gap: 50px;
  margin-top: 50px;

  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    flex-direction: column;
  }
`

// containerWidth: {
//   xs: '100%',
//   sm: '750px',
//   md: '970px',
//   lg: '1170px',
// },

export const CustomContainer = styled.div`
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  width: ${({ theme }) => `calc(100% - 30px)`};
  @media (min-width: ${({ theme }) => theme.breakpoint.sm}) {
    width: ${({ theme }) => '750px'};
  }
  @media (min-width: ${({ theme }) => theme.breakpoint.md}) {
    width: ${({ theme }) => '970px'};
  }
  @media (min-width: ${({ theme }) => '1500px'}) {
    width: ${({ theme }) => '1570px'};
  }
`
