import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import GlobalStyle from './Global.Styled'
import { ThemeProvider } from 'styled-components'
import theme from './theme'
import './global.scss'
import Home from './components/Home/Home'
import News from './components/News/News'
import Software from './components/Software/Software'
import Cloud from './components/Cloud/Cloud'
import Digitalization from './components/Digitalization/Digitalization'
import Imprint from './components/Imprint/Imprint'
import DataProtection from './components/DataProtection/DataProtection'
import GTC from './components/GTC/GTC'
import Contact from './components/Contact/Contact'
import Login from './components/Login/Login'
import Workshop from './components/Workshop/Workshop.js'

function App() {
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <Router>
          <Routes>
            <Route index element={<Home />} />
            <Route exact path={'software'} element={<Software />} />
            <Route exact path={'cloud'} element={<Cloud />} />
            <Route exact path={'digitalization'} element={<Digitalization />} />
            <Route exact path={'workshop'} element={<Workshop />} />
            <Route exact path={'contact'} element={<Contact />} />
            <Route exact path={'news/*'} element={<News />} />
            <Route exact path={'imprint'} element={<Imprint />} />
            <Route exact path={'data-protection'} element={<DataProtection />} />
            <Route exact path={'gtc'} element={<GTC />} />
            <Route exact path={'login'} element={<Login />} />
            <Route path={'*'} element={<Navigate replace to="/" />} />
          </Routes>
        </Router>
        <ToastContainer />
      </ThemeProvider>
    </div>
  )
}

export default App
