import styled from 'styled-components'
import bg from '../../../assets/image/generic/topicSubpage/intro/bg.png'

export const StyledTopicSubpage = styled.div``

export const BG = styled.div`
  background-image: url(${bg});
  background-size: 100% 100%;
  background-position: center bottom;
  background-repeat: no-repeat;
  padding-bottom: 100px;
  @media (max-width: ${({ theme }) => theme.breakpoint.sm}) {
    padding-bottom: 50px;
  }
`
