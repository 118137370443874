import styled from 'styled-components'

export const StyledCard = styled.div`
  margin-top: 15px;
  width: calc(100% / 3 - 17px);
  background-color: ${({ theme }) => theme.color.background.primary};
  -webkit-box-shadow: 10px 10px 38px -7px rgba(142, 158, 248, 1);
  -moz-box-shadow: 10px 10px 38px -7px rgba(142, 158, 248, 1);
  box-shadow: 10px 10px 38px -7px rgba(142, 158, 248, 1);
  position: relative;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    width: calc(100% / 2 - 17px);
  }
  @media (max-width: ${({ theme }) => theme.breakpoint.sm}) {
    width: 100%;
  }
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 20px 30px;
  height: 100%;
  box-sizing: border-box;
  -webkit-box-shadow: -10px -10px 38px -7px rgba(255, 255, 255, 1);
  -moz-box-shadow: -10px -10px 38px -7px rgba(255, 255, 255, 1);
  box-shadow: -10px -10px 38px -7px rgba(255, 255, 255, 1);
`

export const Name = styled.div`
  font-family: 'bold';
  margin-top: 10px;
`
export const Text = styled.div`
  font-size: ${({ theme }) => theme.font.size.sm};
  margin-top: 15px;
`

export const Point = styled.img`
  width: 30px;
  height: 30px;
  position: absolute;
  top: 10px;
  right: 10px;
`
