import { Container } from '@think-internet/ui-components'
import { useSelector } from 'react-redux'
import props from '../../redux/props'
import Layout from '../Layout/Layout'
import SubpageHero from '../SubComponents/SubpageHero/SubpageHero'
import { StyledImprint, Subheadline, P, Strong } from './Imprint.Styled'

const Imprint = () => {
  const translation = useSelector((s) => s[props.TRANSLATION])
  const lang = useSelector((s) => s[props.CURRENTLANG])

  return (
    <Layout authRequired={false}>
      <StyledImprint>
        <SubpageHero>{translation.imprint.headline}</SubpageHero>
        <Container>
          {lang === 'EN' && (
            <>
              <Subheadline>Information pursuant to Sect. 5 German Telemedia Act (TMG)</Subheadline>
              <P>
                Think Internet Operations GmbH
                <br />
                Ulmenweg 2<br />
                87779 Trunkelsberg
              </P>
              <P>
                Commercial Register: 19643
                <br />
                Registration court: Handelsregister B des Amtsgerichts Memmingen
              </P>
              <P>
                <Strong>Represented by:</Strong>
                <br />
                Tim Haag
              </P>
              <Subheadline>Contact</Subheadline>
              <P>
                Phone: +491712181570
                <br />
                E-mail: homepage@think-internet.de
              </P>
              <Subheadline>VAT ID</Subheadline>
              <P>
                Sales tax identification number according to Sect. 27 a of the Sales Tax Law:
                <br />
                DE349498827
              </P>
              <Subheadline>Person responsible for editorial</Subheadline>
              <P>Tim Haag</P>
              <Subheadline>EU dispute resolution</Subheadline>
              <P>
                The European Commission provides a platform for online dispute resolution (ODR):
                <a href="https://ec.europa.eu/consumers/odr" target="_blank" rel="noopener noreferrer">
                  https://ec.europa.eu/consumers/odr
                </a>
                .<br /> Our e-mail address can be found above in the site notice.
              </P>
              <Subheadline>Dispute resolution proceedings in front of a consumer arbitration board</Subheadline>
              <P>
                We participate in a dispute settlement procedure before a consumer arbitration board. The competent consumer arbitration board is
                Zentrum für Schlichtung e.V., Straßburger Straße 8, 77694 Kehl am Rhein (
                <a href="https://www.verbraucher-schlichter.de" rel="noopener noreferrer" target="_blank">
                  https://www.verbraucher-schlichter.de
                </a>
                ).
              </P>
              <Subheadline>Liability for Contents</Subheadline>
              <P>
                As service providers, we are liable for own contents of these websites according to Paragraph 7, Sect. 1 German Telemedia Act (TMG).
                However, according to Paragraphs 8 to 10 German Telemedia Act (TMG), service providers are not obligated to permanently monitor
                submitted or stored information or to search for evidences that indicate illegal activities.
              </P>
              <P>
                Legal obligations to removing information or to blocking the use of information remain unchallenged. In this case, liability is only
                possible at the time of knowledge about a specific violation of law. Illegal contents will be removed immediately at the time we get
                knowledge of them.
              </P>
              <Subheadline>Liability for Links</Subheadline>
              <P>
                Our offer includes links to external third-party websites. We have no influence on the contents of those websites, therefore we cannot
                guarantee for those contents. Providers or administrators of linked websites are always responsible for their own contents.
              </P>
              <P>
                The linked websites had been checked for possible violations of law at the time of the establishment of the link. Illegal contents
                were not detected at the time of the linking. A permanent monitoring of the contents of linked websites cannot be imposed without
                reasonable indications that there has been a violation of law. Illegal links will be removed immediately at the time we get knowledge
                of them.
              </P>
              <Subheadline>Copyright</Subheadline>
              <P>
                Contents and compilations published on these websites by the providers are subject to German copyright laws. Reproduction, editing,
                distribution as well as the use of any kind outside the scope of the copyright law require a written permission of the author or
                originator. Downloads and copies of these websites are permitted for private use only.
                <br /> The commercial use of our contents without permission of the originator is prohibited.
              </P>
              <P>
                Copyright laws of third parties are respected as long as the contents on these websites do not originate from the provider.
                Contributions of third parties on this site are indicated as such. However, if you notice any violations of copyright law, please
                inform us. Such contents will be removed immediately.
              </P>
            </>
          )}
          {lang === 'DE' && (
            <>
              <Subheadline>Angaben gemäß § 5 TMG</Subheadline>
              <P>
                Think Internet Operations GmbH
                <br />
                Ulmenweg 2<br />
                87779 Trunkelsberg
              </P>
              <P>
                Handelsregister: 19643
                <br />
                Registergericht: Handelsregister B des Amtsgerichts Memmingen
              </P>
              <P>
                <Strong>Vertreten durch:</Strong>
                <br />
                Tim Haag
              </P>
              <Subheadline>Kontakt</Subheadline>
              <P>
                Telefon: +491712181570
                <br />
                E-Mail: homepage@think-internet.de
              </P>
              <Subheadline>Umsatzsteuer-ID</Subheadline>
              <P>
                Umsatzsteuer-Identifikationsnummer gemäß § 27 a Umsatzsteuergesetz:
                <br />
                DE349498827
              </P>
              <Subheadline>Redaktionell Verantwortlicher</Subheadline>
              <P>Tim Haag</P>
              <Subheadline>EU-Streitschlichtung</Subheadline>
              <P>
                Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit:
                <a href="https://ec.europa.eu/consumers/odr" target="_blank" rel="noopener noreferrer">
                  https://ec.europa.eu/consumers/odr
                </a>
                .<br /> Unsere E-Mail-Adresse finden Sie oben im Impressum.
              </P>
              <Subheadline>Verbraucher­streit­beilegung/Universal­schlichtungs­stelle</Subheadline>
              <P>
                Wir nehmen an einem Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teil. Zuständig ist die
                Universalschlichtungsstelle des Zentrums für Schlichtung e.V., Straßburger Straße 8, 77694 Kehl am Rhein (
                <a href="https://www.verbraucher-schlichter.de" rel="noopener noreferrer" target="_blank">
                  https://www.verbraucher-schlichter.de
                </a>
                ).
              </P>
              <Subheadline>Haftung für Inhalte</Subheadline>
              <P>
                Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich.
                Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen zu
                überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen.
              </P>
              <P>
                Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt.
                Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden
                von entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.
              </P>
              <Subheadline>Haftung für Links</Subheadline>
              <P>
                Unser Angebot enthält Links zu externen Websites Dritter, auf deren Inhalte wir keinen Einfluss haben. Deshalb können wir für diese
                fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber
                der Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft.
                Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar.
              </P>
              <P>
                Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht
                zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Links umgehend entfernen.
              </P>
              <Subheadline>Urheberrecht</Subheadline>
              <P>
                Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen Urheberrecht. Die
                Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der
                schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite sind nur für den privaten, nicht
                kommerziellen Gebrauch gestattet.
              </P>
              <P>
                Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte Dritter beachtet. Insbesondere
                werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, bitten wir
                um einen entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.
              </P>
            </>
          )}
        </Container>
      </StyledImprint>
    </Layout>
  )
}

export default Imprint
