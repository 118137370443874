import styled from 'styled-components'
import defaultBG from '../../../assets/image/generic/subpageHero/defaultBG.png'

export const StyledSubpageHero = styled.div`
  margin-top: 175px;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    margin-top: 100px;
  }
  @media (max-width: ${({ theme }) => theme.breakpoint.sm}) {
    width: 100%;
  }
`

export const Content = styled.div`
  background-image: url(${({ bg }) => bg || defaultBG});
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 200px 0px;
  color: ${({ theme }) => theme.color.font.white};
  font-size: ${({ theme }) => theme.font.size.headline};
  font-style: italic;
  font-family: 'bold';
  text-transform: uppercase;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    font-size: ${({ theme }) => theme.font.size.xlg};
    padding: 100px 0px;
  }
  @media (max-width: ${({ theme }) => theme.breakpoint.sm}) {
    width: 100%;
    font-size: ${({ theme }) => theme.font.size.lg};
    background-size: auto 100%;
  }
`
