import { useSelector } from 'react-redux'
import { StyledFooter, Column, Flex, Text, ImageWrapper, Logo, CustomLink, BVMWLogo } from './Footer.Styled'
import { Container } from '@think-internet/ui-components'
import props from '../../../redux/props'
import logo from '../../../assets/image/generic/logo.svg'
import bvmw from '../../../assets/image/footer/bvmw.svg'

const Footer = () => {
  const translation = useSelector((s) => s[props.TRANSLATION])

  const getCurrentFullYear = () => {
    const date = new Date()
    return date.getFullYear()
  }

  return (
    <StyledFooter>
      <Container>
        <Flex justify={'space-between'}>
          <Column>
            <Text>
              &copy;{` ${getCurrentFullYear()} `}
              {translation.genericInfo.companyName}
            </Text>
          </Column>
          <Column>
            <CustomLink href="/imprint">{translation.imprint.headline}</CustomLink>
            <CustomLink href="/data-protection"> {translation.dataProtection.headline} </CustomLink>
            <CustomLink href="/gtc"> {translation.gtc.headline} </CustomLink>
          </Column>
          <Column>
            <ImageWrapper>
              <Logo src={logo} />
            </ImageWrapper>
          </Column>
          <Column>
            <ImageWrapper>
              <BVMWLogo src={bvmw} />
            </ImageWrapper>
          </Column>
        </Flex>
      </Container>
    </StyledFooter>
  )
}

export default Footer
