import { v4 as uuidv4 } from 'uuid'
const currencyFormatter = require('currency-formatter')
const prettyBytes = require('pretty-bytes')

export const offlineMode = process.env.NODE_ENV !== 'production'

// export const backendDomain = offlineMode ? 'http://localhost:8100' : 'https://blog-backend.think-internet.de'
export const backendDomain = 'https://blog-backend.think-internet.de'

export const getUUID = () => uuidv4()

export const recaptchaV3SiteKey = '6LdvRvoZAAAAAAANoCGCxtsRy1tYl8w45ctNAPCG'

const gatewayPrefix = 'https://w0sqgwdwbl.execute-api.eu-central-1.amazonaws.com/prod'
export const sendMailEndpoint = `${gatewayPrefix}/send-mail`

export const getQueryString = (string, requiredString) => {
  if (typeof string === 'string' && string.length > 0 && typeof requiredString === 'string') {
    string = string.substring(1)
    const split = string.split('=')
    return !string.includes('&') && split.length === 2 && split[0] === requiredString && split[1].length > 0 ? decodeURIComponent(split[1]) : false
  } else {
    return false
  }
}

export const getPrettyTime = (date) => {
  // date.setMinutes(date.getMinutes() + (date.getTimezoneOffset() * -1))
  const fill = (number) => {
    number = `${number}`
    return number.length === 1 ? `0${number}` : number
  }
  const minute = fill(date.getMinutes())
  const hour = fill(date.getHours())
  return `${hour}:${minute}`
}
export const getPrettyDateTime = (date) => {
  // date.setMinutes(date.getMinutes() + (date.getTimezoneOffset() * -1))
  const fill = (number) => {
    number = `${number}`
    return number.length === 1 ? `0${number}` : number
  }
  const day = fill(date.getDate())
  const month = fill(date.getMonth() + 1)
  const year = date.getFullYear()
  const minute = fill(date.getMinutes())
  const hour = fill(date.getHours())
  return `${day}.${month}.${year} ${hour}:${minute}`
}
export const getPrettyDate = (date) => {
  const fill = (number) => {
    number = `${number}`
    return number.length === 1 ? `0${number}` : number
  }
  const day = fill(date.getDate())
  const month = fill(date.getMonth() + 1)
  const year = date.getFullYear()
  return `${day}.${month}.${year}`
}

export const addMissingZero = (input) => {
  if (`${input}`.length === 1) {
    return `0${input}`
  }
  return input
}

export const formatFileSize = (sizeInBytes, locale = 'de') => {
  return prettyBytes(sizeInBytes, { locale })
}

export const formatPrice = (priceInCents, locale = 'de-DE') => {
  return currencyFormatter.format(priceInCents / 100, { locale })
}

export const getLocalURLOfFile = (file) => URL.createObjectURL(file)

export const s3StorageBucketPublicURLPrefix = 'https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.homepage.storage/'
