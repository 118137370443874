import TopicSubpage from '../SubComponents/TopicSubpage/TopicSubpage'
import reliable from '../../assets/image/cloud/sequence/reliable.svg'
import flexiblity from '../../assets/image/cloud/sequence/flexiblity.svg'
import performance from '../../assets/image/cloud/sequence/performance.svg'
import security from '../../assets/image/cloud/sequence/security.svg'

const Cloud = () => {
  const sequenceImages = [security, flexiblity, performance, reliable]
  return <TopicSubpage translationRootKey={'cloud'} sequenceImages={sequenceImages} />
}

export default Cloud
