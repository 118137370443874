import styled from 'styled-components'

export const StyledSmallContainer = styled.div`
  width: 50%;
  box-sizing: border-box;
  ${({ align }) => {
    if (align === 'center') {
      return `
      margin-left: auto;
      margin-right: auto;
      padding: 0 15px;
      `
    } else if (align === 'left') {
      return `
      margin-left: 0;
      margin-right: auto;
      `
    } else if (align === 'right') {
      return `
      margin-left: auto;
      margin-right: 0;
      `
    }
  }}

  @media (max-width: ${({ theme }) => theme.breakpoint.lg}) {
    width: 85%;
  }
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    width: 100%;
  }
`
