import { useSelector } from 'react-redux'
import props from '../../../redux/props'
import { ContentLeft, ContentRight, CTA, Headline, Left, Right, StyledSplit, Text, Icon } from './Split.Styled'
import logoIcon from '../../../assets/image/generic/logo-icon-outline.svg'

const Split = () => {
  const translation = useSelector((s) => s[props.TRANSLATION])
  return (
    <StyledSplit>
      <Left>
        <ContentLeft>
          <Headline>{translation.home.split.headline}</Headline>
          <Text>{translation.home.split.text}</Text>
          <CTA href="#contact">{translation.home.split.cta}</CTA>
        </ContentLeft>
      </Left>
      <Right>
        <ContentRight>
          <Icon src={logoIcon} />
        </ContentRight>
      </Right>
    </StyledSplit>
  )
}

export default Split
