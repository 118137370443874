import { Content, StyledHero } from './Hero.Styled'

const Hero = ({ children, bg }) => {
  return (
    <StyledHero bg={bg}>
      <Content>{children}</Content>
    </StyledHero>
  )
}

export default Hero
