import { Form } from '@think-internet/ui-components'
import styled from 'styled-components'
import bg from '../../../assets/image/generic/contactForm/bg.png'

export const StyledContactForm = styled.div`
  margin-top: 200px;
  padding: 50px 0;
  background-image: url(${bg});
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    margin-top: 100px;
    padding: 50px 0px;
    background-size: auto 100%;
  }
  @media (max-width: ${({ theme }) => theme.breakpoint.sm}) {
    margin-top: 50px;
  }
`

export const ContentWrapper = styled.div`
  background-color: ${({ theme }) => theme.color.background.primary};
  -webkit-box-shadow: 10px 10px 38px -7px rgba(142, 158, 248, 1);
  -moz-box-shadow: 10px 10px 38px -7px rgba(142, 158, 248, 1);
  box-shadow: 10px 10px 38px -7px rgba(142, 158, 248, 1);
`

export const Content = styled.div`
  background-color: ${({ theme }) => theme.color.background.primary};
  padding: 75px 100px;
  -webkit-box-shadow: -10px -10px 38px -7px rgba(255, 255, 255, 1);
  -moz-box-shadow: -10px -10px 38px -7px rgba(255, 255, 255, 1);
  box-shadow: -10px -10px 38px -7px rgba(255, 255, 255, 1);
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    padding: 25px;
  }
`

export const Split = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 15px;
  @media (max-width: ${({ theme }) => theme.breakpoint.sm}) {
    flex-direction: column;
  }
`

export const Page = styled.div`
  width: 50%;
  @media (max-width: ${({ theme }) => theme.breakpoint.sm}) {
    width: 100%;
  }
`

export const Subline = styled.div`
  font-size: ${({ theme }) => theme.font.size.sm};
  margin-bottom: 50px;
  width: 75%;
  @media (max-width: ${({ theme }) => theme.breakpoint.sm}) {
    width: 100%;
    margin-bottom: 25px;
  }
`

export const Title = styled.div`
  font-size: ${({ theme }) => theme.font.size.lg};
  font-family: 'bold';
  text-transform: uppercase;
  margin-top: 25px;
`

export const Value = styled.a`
  text-decoration: none;
`

export const CTA = styled.button`
  border: none;
  width: fit-content;
  text-transform: uppercase;
  font-family: 'bold';
  background: rgb(116, 250, 253);
  background: linear-gradient(90deg, rgba(116, 250, 253, 1) 0%, rgba(141, 159, 248, 1) 100%);
  padding: 7.5px 25px;
  text-decoration: none;
  margin-left: auto;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  &:hover {
    transform: scale(1.05);
  }
`

export const InternalForm = styled(Form)`
  display: flex;
  flex-direction: column;
  gap: 10px;
  @media (max-width: ${({ theme }) => theme.breakpoint.sm}) {
    margin-top: 25px;
  }
`

export const Message = styled.div`
  font-size: ${({ theme }) => theme.font.size.sm};
  font-family: 'bold';
`
