import styled from 'styled-components'

export const StyledItem = styled.div`
  background-color: ${({ theme }) => theme.color.background.white};
  padding: 10px;
  border-radius: ${({ theme }) => theme.border.radius};
  box-shadow: ${({ theme }) => theme.shadow};
`

export const Image = styled.div`
  background-image: url(${({ src }) => src});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  height: 100px;
  width: 100%;
`

export const Column = styled.div`
  width: 100%;
`

export const Headline = styled.div`
  font-family: bold;
  margin-top: 10px;
  font-size: ${({ theme }) => theme.font.size.md};
`

export const Wrapper = styled.div`
  margin-top: ${({ odd }) => (odd ? '100px' : '0px')};
  width: calc(25% - 50px);

  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    width: 100%;
    margin-top: 0px;
  }
`
