import { StyledHeadline } from './Headline.Styled'

const Headline = ({ text, align, color, width, className }) => {
  return (
    <StyledHeadline className={className} align={align} color={color} width={width}>
      {text}
    </StyledHeadline>
  )
}

export default Headline
