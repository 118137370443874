import { StyledArticle, Title, Split, ImageWrapper, Image, Content, Header, Text, FormattedDate } from './Article.Styled'
import { getPrettyDate } from '../../../../utility'

const Article = ({ data }) => {
  return (
    <StyledArticle href={`/news/${data.uuid}`}>
      <Split>
        <ImageWrapper>
          <Image src={data.heroImageURL} />
        </ImageWrapper>
        <Content>
          <Header>
            <Title>{data.headline}</Title>
            <FormattedDate>{getPrettyDate(new Date(data.createdOn))}</FormattedDate>
          </Header>
          <Text>{data.text}</Text>
        </Content>
      </Split>
    </StyledArticle>
  )
}

export default Article
