import TopicSubpage from '../SubComponents/TopicSubpage/TopicSubpage'
import processes from '../../assets/image/digitalization/sequence/processes.svg'
import communication from '../../assets/image/digitalization/sequence/communication.svg'
import data from '../../assets/image/digitalization/sequence/data.svg'
import everything from '../../assets/image/digitalization/sequence/everything.svg'

const Digitalization = () => {
  const sequenceImages = [data, processes, communication, everything]
  return <TopicSubpage translationRootKey={'digitalization'} sequenceImages={sequenceImages} />
}

export default Digitalization
