//
// ONLY EDIT THIS FILE IN /frontend/src/
//

const translation = {
  nav: {
    items: [
      { text: 'Home', href: '' },
      { text: 'Software', href: 'software' },
      { text: 'Cloud', href: 'cloud' },
      { text: 'Digitalisierung', href: 'digitalization' },
      // { text: 'Workshop', href: 'workshop' },
      // { text: 'News', href: 'news' },
      { text: 'Kontakt', href: 'contact' },
    ],
    admin: {
      items: [
        { text: 'Users', href: 'dashboard/admin/users' },
        { text: 'Offers', href: 'dashboard/admin/offers' },
        { text: 'News', href: 'dashboard/admin/news' },
      ],
    },
    manager: {
      items: [{ text: 'News', href: 'dashboard/admin/news' }],
    },
    logout: 'Logout',
  },

  home: {
    whoWeAre: {
      headline: 'Wer wir sind',
      text: 'Wir sind darauf spezialisiert, innovative Softwarelösungen für Unternehmen jeglicher Branche zu entwickeln. Unser Team hat sich auf die Erstellung maßgeschneiderter Softwarelösungen spezialisiert, die auf Kosteneffektivität, Automatisierung und Zukunftsfähigkeit ausgerichtet - und somit nachhaltig sind. Wir nutzen state-of-the-art Technologien, einschließlich künstlicher Intelligenz, um sicherzustellen, dass unsere Software einen Mehrwert für unsere Kunden bietet, ohne Trends blind zu folgen. Ob eine einfache Anwendung oder ein komplexes firmenübergreifendes System - Think Internet verfügt über das Fachwissen und die Erfahrung, um Ihnen bei der Umsetzung Ihrer individuellen Ziele zu helfen.',
    },
    perfection: {
      subline:
        'Ob Software, Cloud oder Digitalisierung von Unternehmen - Think Internet ist der Partner für Ihre digitale Zukunft. Deswegen legen wir unseren Fokus auf folgende Punkte:',
      points: [
        { title: 'Kosten', text: 'Minimierung Ihrer laufenden IT-Kosten' },
        { title: 'Zukunftsfähig', text: 'Zukunftsfähigkeit Ihrer Cloud-Architektur und Software' },
        { title: 'Automatisierung', text: 'Automatisierung und Zentralisierung Ihrer Daten und Prozesse' },
      ],
    },
    split: {
      headline: 'Anpassbarkeit',
      text: 'Technologien und ihre Anforderungen sind im permanenten Wandel - und dass in zunehmender Geschwindigkeit. Wir wollen, dass unsere Kunden den einen Schritt voraus sind, um wettbewerbsfähig zu bleiben. Aus diesem Grund arbeiten wir eng und langfristig mit unseren Kunden zusammen, um ihre einzigartigen Herausforderungen zu verstehen und kundenspezifische Softwarelösungen zu entwickeln.',
      cta: 'Hier klicken',
    },
    services: {
      headline: 'Leistungen',
      cta: 'Hier klicken',
      items: [
        { name: 'Softwareentwicklung', text: 'Individuelle Softwareentwicklung in der Cloud mit state-of-the-art Technologien.', href: '/software' },
        { name: 'Cloud', text: 'Konzeption und Aufbau Ihrer individuellen Cloud-Architektur.', href: '/cloud' },
        {
          name: 'Digitialisierung',
          text: 'Optimierung, Zentralisierung und Automatisierung Ihrer Daten und Prozesse.',
          href: '/digitalization',
        },
      ],
    },
    block: {
      cta: 'Hier klicken',
    },
    references: {
      headline: 'Referenzen',
      items: [
        {
          title: 'Mediengruppe RTL Deutschland GmbH',
          text: 'Im Rahmen der Konzeption und Entwicklung von RTL+, hat Think Internet im Bereich der Webentwicklung und Cloud-Konzeption zur erfolgreichen Umsetzung der Platform beigetragen.',
        },
        {
          title: 'AutoScout24',
          text: 'Im Kontext des Redesignes der gesamten AutoScout24-Plattform, hat Think Internet bei der Umsetzung des neuen Designs und dem Refactoring bestehender Features für Endnutzer unterstützt.',
        },
        {
          title: 'Hermes Germany GmbH',
          text: 'Eine sichere und stabile Code-Basis ist elementar für die kommenden Entwicklungsschritte einer Software. Aus diesem Grund hat Think Internet eine bestehende zentrale Analysesoftware von Grund auf analysiert und optimiert, damit den weiteren Entwicklungen nichts im Wege steht.',
        },
        {
          title: 'Chrono24 GmbH',
          text: 'Als größter Marktplatz für Luxus-Uhren weltweit, müssen Cloud-Architektur und Software optimal zusammen arbeiten. Think Internet hat hierbei an der Entwicklung und Verbesserung neuer Features für Endkunden gearbeitet.',
        },
        {
          title: 'Akamai Technologies',
          text: 'Für einen der weltweit größten CDN-Anbieter wurde eine firmenübergreifende cloud-basierte Software entwickelt, um einen zentralen Überblick über Fortbildungskosten und Hierarchien zu erhalten, sowie Vorhersagen über kommende Kosten zu erstellen.',
        },
        {
          title: 'BAYERISCHE KABELWERKE AG',
          text: 'Um den Kunden eine komfortable Möglichkeit zu bieten, bestehende Kabel mit dem Angebotssortiment der bayerischen Kabelwerte zu ersetzen. Wurde ein intelligenter cloud-basierter Konfigurator entwickelt um automatisiert das bestmögliche Angebot aus tausenden Kombinationen für den Kunden zu finden.',
        },
        {
          title: 'uptodate Ventures GmbH',
          text: 'Durch das starke Wachstum, des Tochterunternehmens der Versicherungskammer Bayern, müssen effektive Wege gefunden werden eine skalierbare cloud-basierte Architektur zu erschaffen. Think Internet unterstützte dabei den Aufbau dieser Architektur und die Entwicklung neuer Features.',
        },
      ],
    },
  },

  software: {
    headline: 'Softwareentwicklung',
    intro: {
      title: 'WIR SCHAFFEN LÖSUNGEN BEVOR PROBLEME ENTSTEHEN',
      text: 'Wir legen besonderen Wert auf präventive Softwareentwicklung. Eine frühzeitige Identifikation potenzieller Risiken trägt dazu bei, teure Umbauten und Sicherheitsprobleme zu vermeiden.',
    },
    sequence: {
      title: 'Ablauf',
      items: [
        {
          name: 'Analyse',
          text: 'Eine gründliche Analyse der Anforderungen ist der Schlüssel für eine erfolgreiche Umsetzung. Wir arbeiten uns in Ihre Prozesse ein um die optimale Lösung für Sie entwickeln zu können.',
        },
        {
          name: 'Konzeption',
          text: 'Eine sorgfältige Konzeption legt den Grundstein für Ihre gut strukturierte und funktionale Softwarelösung.',
        },
        {
          name: 'Entwicklung',
          text: 'Die Qualität und Sicherheit Ihrer Software ist uns besonders wichtig und durch regelmäßige Updates und Live-Zugriff auf den aktuellen Entwicklungsstand beiben Sie immer up-to-date.',
        },
        {
          name: 'Betreuung',
          text: 'Damit Ihre Anwendung stehts einsatzfähig und sicher bleibt, unterstützen wir Sie auch nach Abschluss der Entwicklung mit Sicherheitsupdates, Funktionsupdates- und -Upgrades sowie Aktualisierung Ihrer Cloud-Architektur.',
        },
      ],
    },
    technologies: {
      headline: 'Technologien',
      subline:
        'Think Internet nutzt eine Vielzahl von Technologien der Softwareentwicklung, um innovative und zukunftsweisende Softwarelösungen zu entwickeln. Dazu gehören unter anderem Cloud-Technologien, künstliche Intelligenz und eine agile Entwicklungsmethodik. Wir setzen auf state-of-the-art Technologien und Best Practices, um unseren Kunden die optimale Lösungen zu bieten.',
      items: [
        {
          name: 'ZEUS',
          text: 'Zeus ist unsere auf NodeJS basierende in-house Software mit integrierter Authentifizierung und Autorisierung sowie Webserver, Schnittstellenverwaltung, Datenverarbeitung, Datenbankverwaltung, Einbindung externer Dienste und Protokollierung von Zugriffen. Mittels ZEUS haben wir die Möglichkeit deutliche schneller gewünschte Funktionen Ihrer Software umzusetzen.',
        },
        {
          name: 'NodeJS',
          text: 'NodeJS ermöglicht es uns, schnell skalierbare und performante Webanwendungen zu entwickeln. Durch die Verwendung von NodeJS können wir sowohl auf der Server- als auch auf der Client-Seite JavaScript-Code verwenden, was die Entwicklung und Wartung von Cloud-Anwendungen beschleunigt. Wir nutzen NodeJS, um unseren Kunden maßgeschneiderte und leistungsfähige Cloud-Anwendungen anzubieten.',
        },
        {
          name: 'REACTJS',
          text: 'ReactJS ist unsere bevorzugte JavaScript-Bibliothek für die Frontend-Entwicklung. Die Verwendung von ReactJS ermöglicht es uns, benutzerfreundliche und interaktive Webanwendungen schnell und effizient zu entwickeln. Die Wiederverwendbarkeit von Komponenten in ReactJS trägt zur Erhöhung der Produktivität und der Wartbarkeit unserer Software bei.',
        },
        {
          name: 'CONTINUOUS DEPLOYMENT',
          text: 'Continuous Deployment ist ein wichtiger Bestandteil der agilen Entwicklungsmethodik der Think Internet. Durch die kontinuierliche Lieferung von qualitativ hochwertiger Software können wir sicherstellen, dass unsere Kunden immer auf dem neuesten Stand sind und von den neuesten Funktionen und Verbesserungen profitieren. Mit einer automatisierten Testinfrastruktur und einer schnellen Freigabe- und Bereitstellungs-Pipeline können wir unseren Kunden einen schnellen und zuverlässigen Service bieten.',
        },
      ],
    },
  },
  cloud: {
    headline: 'Cloud',
    intro: {
      title: 'Sicherheit, Verfügbarkeit und Performace',
      text: 'Ihre Cloudarchitektur wird individuell auf Sie angepasst um Ihnen die bestmögliche Sicherheit, Verfügbarkeit und Performace zu gewährleisten.',
    },
    sequence: {
      title: 'Merkmale',
      subline: 'Think Internet konzipiert und entwickelt Ihre Cloud-Architektur auf Basis der folgenden Merkmale:',
      items: [
        {
          name: 'Sicherheit',
          text: 'Wir setzen auf bewährte und moderne Sicherheitsmechanismen, um die Daten unserer Kunden zu schützen. Darüber hinaus überwachen wir unsere Cloud-Infrastruktur kontinuierlich und ergreifen proaktiv Maßnahmen, um potenzielle Bedrohungen zu identifizieren und zu beseitigen.',
        },
        {
          name: 'Flexibilität',
          text: 'Wir passen Ihre Cloud-Infrastruktur an die Anforderungen Ihres Unternehmens an. Durch die flexible Skalierbarkeit können Unternehmen schnell auf sich ändernde Anforderungen reagieren und ihre IT-Ressourcen effektiver nutzen. So können Sie Ihr Geschäft agiler und wettbewerbsfähiger gestalten.',
        },
        {
          name: 'Performance',
          text: 'Unsere Cloud-Services werden auf modernster Hardware bereitgestellt, damit unsere Kunden schnellen und zuverlässigen Zugriff auf Ihre Daten haben.',
        },
        {
          name: 'Verfügbarkeit',
          text: 'Unsere Kunden profitieren so von einer hohen Verfügbarkeit und einer nahtlosen Ausfallsicherheit ihrer Cloud-Lösungen durch redundante Infrastruktur und automatisierte Backups.',
        },
      ],
    },
    technologies: {
      headline: 'Technologien',
      subline:
        'Cloud-Technologien haben in den letzten Jahren eine rasante Entwicklung erfahren und bieten Unternehmen heute viele Vorteile gegenüber traditionellen IT-Infrastrukturen. Cloud-Lösungen ermöglichen eine flexible, skalierbare und kosteneffiziente Bereitstellung von IT-Ressourcen und Anwendungen. Darüber hinaus bieten sie eine hohe Verfügbarkeit, Sicherheit und Leistungsfähigkeit.',
      items: [
        {
          name: 'AWS',
          text: 'Die Amazon Web Services (AWS) ist einer der führenden Cloud-Provider und Hyperscaler wodurch wir Ihre Lösung auf einer hoch-verfügbaren, performanten und flexiblen Basis konzipieren und umsetzen können. AWS bietet eine breite Palette von Cloud-Services, darunter Computing, Storage, Datenbanken und Netzwerke. Dennoch gibt es Fälle in denen AWS nicht die beste Lösung ist. Wir beraten Sie gerne und finden die beste Lösung für Ihr Unternehmen.',
        },
        {
          name: 'Zugriffsmanagement',
          text: 'Unsere Lösungen sind in der Hardware sowie Software auf Sicherheit konzipiert, hierbei wird jede Datenänderung protokolliert und jeder Zugriff authorisiert. Sie können die Zugriffsrechte für Benutzer und Gruppen festlegen, die auf Ihre Daten und Funkionen zugreifen dürfen. Der Datenverkehr unserer Lösungen erfolgt ausschließlich verschlüsselt.',
        },
        {
          name: 'Container-Technologie',
          text: 'Container-Technologie ermöglicht es, Anwendungen in einer sicheren isolierten Umgebung auszuführen. Container sind leichtgewichtiger als virtuelle Maschinen und erfordern weniger Ressourcen, wodurch Betriebskosten eingespart werden können. Durch die schnelle Inbetriebnahme von Containern kann die IT-Infrastruktur flexibler und effizienter gestaltet werden.',
        },
        {
          name: 'Serverless Computing',
          text: 'Serverless Computing ist ein modernes, skalierbares und im Betrieb kostengünstiges Modell für die Bereitstellung von Anwendungen in der Cloud. Hierbei wird die Software in den Fokus gerückt, wobei der Betrieb der Hardware an den Cloud-Betreiber abgegeben wird. Wir bieten intelligente Lösungen für die Bereitstellung von Serverless-Anwendungen, die sich an die Anforderungen Ihres Unternehmens anpassen.',
        },
      ],
    },
  },
  digitalization: {
    headline: 'Digitalisierung',
    intro: {
      title: 'Was? Warum? Wie?',
      text: 'Jedem ein Begiff, doch was ist überhaupt für Ihr Unternehmen möglich und sinnvoll? Think Internet arbeitet eng mit Ihnen zusammen, um das Thema Digitalisierung effektiv in Ihrem Kontext voran zu treiben.',
    },
    sequence: {
      title: 'Merkmale',
      items: [
        {
          name: 'Daten',
          text: 'Eine klassische Zettelwirtschaft ist heutzutage in fast allen Bereichen überholt. Mittels moderner Cloud-Technologien können Ihre Daten einfach, schnell und von überall aus zugänglich gemacht werden.',
        },
        {
          name: 'Prozesse',
          text: 'Nicht nur Daten können digitalisiert werden, ganze Prozesse Ihres Unternehmens können vereinfacht, zentralisiert und automatisiert werden, wodurch Sie sich auf Ihre wesentlichen Aufgaben konzentrieren können.',
        },
        {
          name: 'Kommunikation',
          text: 'Durch eine intelligente Verwaltung Ihrer Daten können Sie die Kommunikation zwischen Geschäftspartnern, Kunden, Vertriebspartnern etc. automatisieren ohne die persönliche Note zu verlieren.',
        },
        {
          name: 'Zentralisiert',
          text: 'Durch digitale Prozesse und Daten innerhalb Ihres Unternehmens können automatisiert intelligente Statistiken und Zusammenfassungen erstellt werden, wodurch Sie immer und überall auf dem neusten Stand sind.',
        },
      ],
    },
    technologies: {
      headline: 'Technologien',
      subline:
        'Wir setzen auf state-of-the-art Technologien, um unseren Kunden dabei zu helfen, ihre digitalen Workflows zu optimieren und ihre Abläufe zu optimieren. Von AI-betriebenen Chatbots bis hin zur automatisierten Datenanalyse bieten wir eine Vielzahl von modernen Lösungen, die Ihrem Unternehmen helfen, auf dem neuesten Stand zu bleiben.',
      items: [
        {
          name: 'Künstiliche Intelligenz (KI)',
          text: 'Die Integration von Chat GPT ist ein weiterer Schritt, unseren Kunden die besten digitalen Lösungen zu bieten. Mit Hilfe von künstlicher Intelligenz und maschinellem Lernen können wir, beispielsweise, personalisierte Chatbots entwickeln um Ihre Kundenkommunikation zu automatisieren.',
        },
        {
          name: 'Software',
          text: 'Software spielt eine entscheidende Rolle bei der digitalen Transformation von Unternehmen. Sie ermöglicht es, Prozesse zu automatisieren, Daten zu analysieren und Entscheidungen auf der Grundlage von Echtzeitinformationen zu treffen. Bei Think Internet entwickeln wir maßgeschneiderte Softwarelösungen für Unternehmen aller Branchen.',
        },
        {
          name: 'Cloud',
          text: 'Die Cloud ermöglicht es, Daten und Anwendungen in einer sicheren und skalierbaren Umgebung zu speichern und zu verwalten. Wir bieten Cloud-basierte Lösungen, die es Unternehmen ermöglichen, von den Vorteilen der Cloud zu profitieren. Wir helfen unseren Kunden bei der Migration in die Cloud, bei der Integration von Cloud-Services und bei der Entwicklung neuer Cloud-basierter Anwendungen.',
        },
      ],
    },
  },

  customerWorkshop: {
    headline: 'Kundenworkshop',
    subheadline: 'IN 90 MINUTEN GEMEINSAM STRATEGIEN ERARBEITEN, UM AKTUELLE HERAUSFORDERUNGEN ZU LÖSEN.',
    intro: {
      title: 'Erkennen und verwirklichen Sie das Potenzial Ihres Projekts',
      text: 'Unser Kundenworkshop ist der ideale Weg, um Ihre Ideen zu erkunden, Ihre Ziele zu definieren und ein maßgeschneidertes Projekt zu entwickeln. Wir bei Think Internet verstehen, dass jedes Unternehmen einzigartig ist und unterschiedliche Anforderungen hat. Deshalb arbeiten wir eng mit Ihnen zusammen, um Ihre Bedürfnisse zu verstehen und Ihre Vision in die Realität umzusetzen.',
    },
    sequence: {
      title: 'IHR MEHRWERT DES WORKSHOP',
      items: [
        {
          number: '01',
          name: 'Vorbereitung',
          text: 'Bevor der Workshop beginnt, treffen wir uns mit Ihnen, um Ihre Ziele, Erwartungen und Herausforderungen zu besprechen. Auf dieser Grundlage entwickeln wir einen maßgeschneiderten Workshop-Plan, der auf Ihre spezifischen Anforderungen zugeschnitten ist.',
        },
        {
          number: '02',
          name: 'Ideen sammeln',
          text: 'Im Workshop selbst bieten wir eine kreative Atmosphäre, in der alle Teilnehmer frei ihre Ideen, Wünsche und Visionen für das Projekt teilen können. Wir fördern den offenen Austausch und machen uns Notizen, um sicherzustellen, dass nichts übersehen wird.',
        },
        {
          number: '03',
          name: 'Analyse und Priorisierung',
          text: 'Nachdem alle Ideen gesammelt wurden, analysieren wir diese gemeinsam mit Ihnen. Wir identifizieren die wichtigsten Punkte, definieren Prioritäten und entwickeln eine klare Roadmap für die Umsetzung des Projekts.',
        },
        {
          number: '04',
          name: 'Konzeption und Entwicklung',
          text: 'Mit einer klaren Roadmap in der Hand gehen wir an die Konzeption und Entwicklung des Projekts. Dabei berücksichtigen wir Ihre Bedürfnisse, Wünsche und Budgetvorgaben, um ein maßgeschneidertes Ergebnis zu liefern.',
        },
        // {
        //   number: '05',
        //   name: 'Feedback und Anpassungen',
        //   text: 'Während des gesamten Prozesses stehen wir in engem Kontakt mit Ihnen und sammeln regelmäßig Feedback. Dadurch können wir sicherstellen, dass das Projekt kontinuierlich an Ihre Vorstellungen angepasst und optimiert wird.',
        // },
        // {
        //   number: '06',
        //   name: 'Abschluss und Auslieferung',
        //   text: 'Nach erfolgreicher Entwicklung und Abschluss des Projekts stellen wir Ihnen das fertige Produkt vor. Wir unterstützen Sie bei der Implementierung und bieten Schulungen und Support, um sicherzustellen, dass Sie das Beste aus Ihrer neuen Lösung herausholen.',
        // },
      ],
    },
    technologies: {
      title: 'Vorteile eines Kundenworkshops',
      items: [
        {
          name: '_KOSTENFREI',
          text: 'Der Workshop ist und bleibt kostenfrei. Als Erstgespräch nutzen wir den Workshop, damit wir unsere Partner & Kunden kennenlernen und sie einen ersten Eindruck von Think Internet erhalten. Wir sehen die 90 Minuten als beidseitige Investition.',
        },
        {
          name: '_SOFORTIGE HANDLUNGSANSÄTZE',
          text: 'Unser Kundenworkshop bietet Ihnen den Vorteil eines effizienten Entwicklungsprozesses. Durch die intensive Zusammenarbeit und den fokussierten Austausch während des Workshops können wir schnell Fortschritte erzielen und Hindernisse frühzeitig identifizieren und überwinden.',
        },
        {
          name: '_ZEITERSPARNIS',
          text: 'Das erste generelle Gespräch gestalten wir gerne mit Mehrwert und effizient in 90 Minuten.',
        },
        {
          name: '_KOMMUNIKATION',
          text: 'Unser Kundenworkshop bietet Ihnen den Vorteil regelmäßigen Feedbacks und transparenten Kommunikation während des gesamten Projektverlaufs. Wir legen großen Wert darauf, dass Sie als Kunde aktiv in den Entwicklungsprozess eingebunden sind und regelmäßig über den aktuellen Stand des Projekts informiert werden.',
        },
        {
          name: '_ENTSCHEIDUNGEN TREFFEN',
          text: 'Unabhängig von der aktuellen Herausforderung, kann der Workshop-Teilnehmer jedes Thema mit in den Workshop bringen, vor dem er im Unternehmen steht. Im Workshop wird auf spezifische Themen eingegangen und Lösungen erarbeitet.',
        },
        {
          name: '_UNVERBINDLICH',
          text: 'Am Ende des Workshops werden auf signifikante Schritte zur Umsetzung eingegangen. Als Teil der Lösung steht es dem Teilnehmer natürlich frei, diese intern oder mit bestehenden Partnern umzusetzen. Think Internet steht dabei auch strategisch zur Seite.',
        },
      ],
    },
    process: {
      title: 'DER ABLAUF DES WORKSHOPS',
    },
    contact: {
      title: 'DAS BESTE ERSTE KENNENLERNEN',
      items: [
        {
          name: 'DIGITALER QUICK-WORKSHOP',
          text: 'In einem 90-Minuten-Workshop erarbeiten wir gemeinsam erste individuelle Möglichkeiten zur Optimierung als Kennenlernen.',
          sub: 'HIER EINEN KOSTENFREIEN WORKSHOP-TERMIN VEREINBAREN',
        },
        {
          name: 'KURZER TELEFON-CALL',
          text: 'In einem persönlichen Gespräch beantworten wir erste Fragen, sprechen über Vorteile und die allgeemeine Herangehensweise.',
          sub: 'HIER EINEN KOSTENFREIEN 15-MINUTEN-CALL VEREINBAREN',
        },
      ],
    },
  },

  news: {
    headline: 'News',
  },

  mottoBlock: {
    cta: 'Hier klicken',
  },

  contactForm: {
    headline: 'Kontakt',
    subline: 'Kontaktieren Sie uns noch heute und erfahren Sie, wie wir Sie in praktischen Schritten zu Ihrem Ziel führen werden.',
    cta: 'Senden',
    success: 'Vielen Dank für Ihre Nachricht. Wir werden uns so schnell wie möglich bei Ihnen melden.',
    error: 'Leider ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut.',
  },

  placePrediction: {
    input: {
      placeholder: 'Deine Adresse...',
    },
  },

  imprint: {
    headline: 'Impressum',
  },

  dataProtection: {
    headline: 'Datenschutz',
  },

  gtc: {
    headline: 'AGB',
  },

  generic: {
    salutation: 'Anrede',
    title: 'Titel',
    name: 'Name',
    firstname: 'Vorname',
    lastname: 'Nachname',
    address: 'Adresse',
    street: 'Straße',
    postcode: 'Postleitzahl',
    city: 'Stadt',
    phone: 'Telefon',
    fax: 'Fax',
    mail: 'E-Mail',
    password: 'Passwort',
    km: ' km',
    save: 'Speichern',
    remove: 'Löschen',
    message: 'Nachricht',
    description: 'Beschreibung',
    virtualViewingLink: 'Link zur virtuellen Besichtigung',
    contactPerson: 'Ansprechpartner',
    responsiblePerson: 'Leitung',
    externalSignupLink: 'Link zur externen Anmeldung',
    motto: 'DIGITALE LÖSUNGEN MIT PERFEKTION',
    level: {
      ADMIN: 'Administator',
      MANAGER: 'Manager',
    },
  },

  genericInfo: {
    ceoName: 'Tim Haag',
    ceoTitle: 'Gründer und Geschäftsführer',
    ceoMail: 'homepage@think-internet.de',
    companyName: 'Think Internet Operations GmbH',
  },

  login: {
    headline: 'Login',
    cta: 'Einloggen',
    error: 'E-Mail Adresse oder Passwort falsch.',
  },

  UI: {
    dropdown: {
      emptySelection: 'Bitte wählen',
    },
  },
}

module.exports = {
  translation,
  langKey: 'DE',
}
