const props = {
  TOKEN: 'TOKEN',
  ACCOUNT: 'ACCOUNT',
  CURRENTLANG: 'CURRENTLANG',
  TRANSLATION: 'TRANSLATION',

  BLOG_ARTICLE: 'BLOG_ARTICLE',
}

export default props
