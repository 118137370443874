import { Container } from '@think-internet/ui-components'
import { useSelector } from 'react-redux'
import props from '../../../redux/props'
import { StyledTimeline, Content, CTA, Knob, Line, Image, Item, Items, ItemsWrapper, Name, Text, ImageWrapper, Border } from './Timeline.Styled'
import knob1 from '../../../assets/image/generic/timeline/knob-1.svg'
import knob2 from '../../../assets/image/generic/timeline/knob-2.svg'
import { getUUID } from '../../../utility'

const Timeline = ({ items }) => {
  const translation = useSelector((s) => s[props.TRANSLATION])

  return (
    <StyledTimeline>
      <ItemsWrapper>
        <Border position={'top'} />
        <Container>
          <Items>
            {items.map((item, i) => {
              const reverse = i % 2 === 1
              return (
                <Item reverse={reverse} key={getUUID()}>
                  <Content reverse={reverse}>
                    <Name reverse={reverse}>{item.name}</Name>
                    <Text reverse={reverse}>{item.text}</Text>
                    {!!item.href && (
                      <CTA reverse={reverse} href={item.href}>
                        {translation.home.services.cta}
                      </CTA>
                    )}
                  </Content>
                  <Knob src={reverse ? knob2 : knob1} />
                  <ImageWrapper>
                    <Image src={item.image} />
                  </ImageWrapper>
                </Item>
              )
            })}
            <Line />
          </Items>
        </Container>
        <Border position={'bottom'} />
      </ItemsWrapper>
    </StyledTimeline>
  )
}

export default Timeline
