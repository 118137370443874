import { useSelector } from 'react-redux'
import props from '../../../../redux/props'
import Headline from '../../Headline/Headline'
import SmallContainer from '../../SmallContainer/SmallContainer'
import Timeline from '../../Timeline/Timeline'
import { StyledSequence, Subline } from './Sequence.Styled'

const Sequence = ({ translationRootKey, images }) => {
  const translation = useSelector((s) => s[props.TRANSLATION])

  return (
    <StyledSequence>
      <SmallContainer>
        <Headline text={translation[translationRootKey].sequence.title} align={'center'} />
        <Subline>{translation[translationRootKey].sequence.subline}</Subline>
      </SmallContainer>
      <Timeline items={translation[translationRootKey].sequence.items.map((item, i) => ({ ...item, image: images[i] }))} />
    </StyledSequence>
  )
}

export default Sequence
