import styled from 'styled-components'
import bg from '../../../assets/image/home/perfection/bg.png'

export const StyledPerfection = styled.div`
  margin-top: 75px;
  padding: 75px 0 150px;
  background-image: url(${bg});
  background-size: 100% 100%;
  background-repeat: no-repeat;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    background-size: 100% auto;
    padding: 75px 0 50px;
  }
`

export const Subline = styled.div`
  width: 75%;
  margin-top: 25px;
`
export const Points = styled.div`
  margin-top: 75px;
  display: flex;
  justify-content: center;
  gap: 50px;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    flex-direction: column;
    align-items: center;
  }
`
export const Point = styled.div`
  width: 250px;
  background-color: ${({ theme }) => theme.color.background.primary};
  -webkit-box-shadow: 10px 10px 38px -7px rgba(142, 158, 248, 1);
  -moz-box-shadow: 10px 10px 38px -7px rgba(142, 158, 248, 1);
  box-shadow: 10px 10px 38px -7px rgba(142, 158, 248, 1);
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 20px 30px;
  height: 100%;
  box-sizing: border-box;
  -webkit-box-shadow: -10px -10px 38px -7px rgba(255, 255, 255, 1);
  -moz-box-shadow: -10px -10px 38px -7px rgba(255, 255, 255, 1);
  box-shadow: -10px -10px 38px -7px rgba(255, 255, 255, 1);
`
export const Icon = styled.img`
  height: 100px;
`

export const Title = styled.div`
  text-align: center;
  font-family: 'bold';
  font-size: ${({ theme }) => theme.font.size.lg};
`

export const Text = styled.div`
  margin-top: 20px;
  text-align: center;
`
