import { useSelector } from 'react-redux'
import props from '../../../redux/props'
import Headline from '../../SubComponents/Headline/Headline'
import { StyledServices } from './Services.Styled'
import software from '../../../assets/image/home/services/software.svg'
import cloud from '../../../assets/image/home/services/cloud.svg'
import digitalization from '../../../assets/image/home/services/digitalization.svg'

import Timeline from '../../SubComponents/Timeline/Timeline'

const Services = () => {
  const translation = useSelector((s) => s[props.TRANSLATION])
  const images = [software, cloud, digitalization]

  return (
    <StyledServices>
      <Headline text={translation.home.services.headline} align="center" />
      <Timeline items={translation.home.services.items.map((item, i) => ({ ...item, image: images[i] }))} />
    </StyledServices>
  )
}

export default Services
