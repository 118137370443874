import { useSelector } from 'react-redux'
import props from '../../../redux/props'
import { StyledIntro, Subline, Logo } from './Intro.Styled'
import logo from '../../../assets/image/generic/logo.svg'
import Hero from '../../SubComponents/Hero/Hero'
import bg from '../../../assets/image/home/intro/bg.jpg'

const Intro = () => {
  const translation = useSelector((s) => s[props.TRANSLATION])
  return (
    <Hero bg={bg}>
      <StyledIntro>
        <Logo src={logo} />
        <Subline>{translation.generic.motto}</Subline>
      </StyledIntro>
    </Hero>
  )
}

export default Intro
