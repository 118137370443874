import styled from 'styled-components'

export const StyledItem = styled.div`
  background-image: url(${({ src }) => src});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  height: 150px;
  width: 100%;
`

export const Wrapper = styled.div`
  width: calc(33% - 50px);
  background-color: ${({ theme }) => theme.color.background.white};
  padding: 10px;
  border-radius: ${({ theme }) => theme.border.radius};
  box-shadow: ${({ theme }) => theme.shadow};

  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    width: 100%;
    box-sizing: border-box;
  }
`
