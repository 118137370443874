import { StyledItem, Wrapper } from './Item.Styled.js'

const ProcessItem = ({ image }) => {
  return (
    <Wrapper>
      <StyledItem src={image}></StyledItem>
    </Wrapper>
  )
}

export default ProcessItem
