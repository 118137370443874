import styled from 'styled-components'

export const StyledIntro = styled.div`
  padding: 150px 15px;
`

export const Subline = styled.div`
  font-family: 'bold';
  color: ${({ theme }) => theme.color.font.blue};
  font-size: ${({ theme }) => theme.font.size.lg};
  margin-left: 125px;
  margin-top: 25px;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    margin-left: 0px;
    font-size: ${({ theme }) => theme.font.size.md};
    text-align: center;
  }
`

export const Logo = styled.img`
  height: 150px;
  @media (max-width: ${({ theme }) => theme.breakpoint.lg}) {
    height: 125px;
  }
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    height: auto;
    width: 100%;
  }
`
