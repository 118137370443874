import styled from 'styled-components'

export const StyledIntro = styled.div``

export const Title = styled.div`
  text-align: center;
  font-family: 'bold';
  font-size: ${({ theme }) => theme.font.size.lg};
  margin-top: 50px;
  text-transform: uppercase;
`

export const Text = styled.div`
  text-align: center;
  font-size: ${({ theme }) => theme.font.size.sm};
  margin-top: 15px;
`
