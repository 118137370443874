import styled from 'styled-components'

export const StyledCardCollection = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 50px;
  gap: 25px;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    justify-content: center;
  }
`
