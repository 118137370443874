import { Container } from '@think-internet/ui-components'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { list } from '../../../redux/action/crud'
import props from '../../../redux/props'
import { StyledNewsView, Items } from './NewsView.Styled'
import Article from './Article/Article'
import Layout from '../../Layout/Layout'
import Headline from '../../SubComponents/Headline/Headline'

const NewsView = () => {
  const dispatch = useDispatch()
  const translation = useSelector((s) => s[props.TRANSLATION])
  const articles = useSelector((s) => s[props.BLOG_ARTICLE])

  useEffect(() => {
    dispatch(list(props.BLOG_ARTICLE))
  }, [dispatch])

  return (
    <Layout authRequired={false}>
      <StyledNewsView>
        <Container>
          <Headline text={translation.news.headline} />
          {Array.isArray(articles) && (
            <Items>
              {articles.map((a) => (
                <Article key={a.uuid} data={a} />
              ))}
            </Items>
          )}
        </Container>
      </StyledNewsView>
    </Layout>
  )
}

export default NewsView
