import { StyledHeader, Item, LogoWrapper, Logo, ItemWrapper, Hamburger, MobileToggle } from './Header.Styled'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Cookies from 'universal-cookie'
import props from '../../../redux/props'
import { setLocal } from '../../../redux/action/local'
import { levels } from '../../../enum'
import logo from '../../../assets/image/generic/logo-minified.svg'
import logoColor from '../../../assets/image/generic/logo-minified-color.svg'
import hamburger from '../../../assets/image/header/hamburger.png'
import hamburgerInverted from '../../../assets/image/header/hamburger-inverted.png'
import { useEffect, useState } from 'react'

const Header = ({ subpage }) => {
  const translation = useSelector((s) => s[props.TRANSLATION])
  const token = useSelector((s) => s[props.TOKEN])
  const account = useSelector((s) => s[props.ACCOUNT])
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [open, setOpen] = useState(false)
  const [scrolled, setScrolled] = useState(false)

  const logout = () => {
    const cookies = new Cookies()
    cookies.remove('token', { path: '/' })
    dispatch(setLocal(props.ACCOUNT, null))
    dispatch(setLocal(props.TOKEN, null))
    navigate('/')
  }

  const getPath = () => {
    const path = window.location.pathname
    return path.substring(1)
  }

  useEffect(() => {
    const handleScroll = () => {
      const currentScrolled = window.scrollY > window.innerHeight / 3
      if (currentScrolled !== scrolled) {
        setScrolled(currentScrolled)
      }
    }
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [scrolled])

  return (
    <StyledHeader scrolled={scrolled}>
      <LogoWrapper>
        <Logo scrolled={scrolled} src={subpage || open ? logoColor : logo} onClick={() => navigate('/')} />
      </LogoWrapper>
      <ItemWrapper open={open}>
        {!token &&
          translation.nav.items.map((item) => (
            <Item key={item.href} subpage={subpage} scrolled={scrolled} href={`/${item.href}`} isSelected={getPath() === item.href}>
              {item.text}
            </Item>
          ))}
        {token &&
          account &&
          account.level === levels.ADMIN &&
          translation.nav.admin.items.map((item) => (
            <Item key={item.href} subpage={subpage} scrolled={scrolled} href={`/${item.href}`} isSelected={getPath() === item.href}>
              {item.text}
            </Item>
          ))}
        {token &&
          account &&
          account.level === levels.MANAGER &&
          translation.nav.manager.items.map((item) => (
            <Item key={item.href} subpage={subpage} scrolled={scrolled} href={`/${item.href}`} isSelected={getPath() === item.href}>
              {item.text}
            </Item>
          ))}
        {token && (
          <Item subpage={subpage} scrolled={scrolled} onClick={logout} href="/">
            {translation.nav.logout}
          </Item>
        )}
      </ItemWrapper>
      <MobileToggle>
        <Hamburger src={open ? hamburgerInverted : hamburger} onClick={() => setOpen(!open)} />
      </MobileToggle>
    </StyledHeader>
  )
}

export default Header
