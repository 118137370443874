import styled from 'styled-components'

export const StyledArticleView = styled.div``

export const Hero = styled.div`
  width: 100%;
  height: 50vh;
  background-image: url(${({ src }) => src});
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
`

export const PublicSince = styled.div`
  text-align: right;
  padding: 15px 0px;
  color: ${({ theme }) => theme.color.font.hint};
  font-size: ${({ theme }) => theme.font.size.sm};
`
export const Headline = styled.div`
  font-size: ${({ theme }) => theme.font.size.lg};
  text-align: center;
  padding: 15px 0px;
`

export const Text = styled.div`
  white-space: pre-line;
  padding: 15px 0px;
`
