import styled from 'styled-components'
import outline from '../../../assets/image/home/split/outline.png'
import bgLeft from '../../../assets/image/home/split/bg-left.png'
import bgRight from '../../../assets/image/home/split/bg-right.png'

export const StyledSplit = styled.div`
  margin-top: 100px;
  background-image: url(${outline});
  background-size: 100% 100%;
  background-repeat: no-repeat;
  position: relative;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    background-image: none;
  }
`

export const Left = styled.div`
  background-image: url(${bgLeft});
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: left;
  width: 75%;
  position: relative;
  z-index: 2;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    width: 100%;
    background-size: auto 100%;
  }
`

export const ContentLeft = styled.div`
  padding: 200px;
  width: 75%;
  box-sizing: border-box;
  @media (max-width: ${({ theme }) => theme.breakpoint.lg}) {
    padding: 200px 100px;
  }
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    padding: 150px 15px;
    width: 75%;
  }
  @media (max-width: ${({ theme }) => theme.breakpoint.sm}) {
    width: 100%;
  }
`

export const Headline = styled.div`
  font-family: 'bold';
  font-size: ${({ theme }) => theme.font.size.xlg};
  text-transform: uppercase;
`

export const Text = styled.div`
  margin-top: 25px;
  font-family: 'light';
`

export const CTA = styled.a`
  text-decoration: none;
  display: block;
  margin-top: 25px;
  width: fit-content;
  text-transform: uppercase;
  font-family: 'bold';
  background: rgb(116, 250, 253);
  background: linear-gradient(90deg, rgba(116, 250, 253, 1) 0%, rgba(141, 159, 248, 1) 100%);
  padding: 7.5px 25px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  &:hover {
    transform: scale(1.05);
  }
`

export const Right = styled.div`
  margin-left: auto;
  width: 60%;
  height: 100%;
  background-image: url(${bgRight});
  background-size: 100% 100%;
  background-repeat: no-repeat;
  position: relative;
  z-index: 1;
  position: absolute;
  right: 0px;
  top: 15%;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    display: none;
  }
`

export const ContentRight = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
`

export const Icon = styled.img`
  height: 250px;
  position: absolute;
  left: 70%;
  top: 45%;
  transform: translate(-50%, -50%);
`
