import styled from 'styled-components'

export const StyledGTC = styled.div``

export const Subheadline = styled.div`
  font-family: 'bold';
  margin-top: 25px;
`
export const P = styled.div`
  margin-top: 25px;
`

export const Strong = styled.span`
  font-family: 'bold';
`
