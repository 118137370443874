import { StyledWhoWeAre, Text } from './WhoWeAre.Styled'
import Headline from '../../SubComponents/Headline/Headline'
import { useSelector } from 'react-redux'
import props from '../../../redux/props'
import { Container } from '@think-internet/ui-components'

const WhoWeAre = () => {
  const translation = useSelector((s) => s[props.TRANSLATION])

  return (
    <StyledWhoWeAre>
      <Container>
        <Headline align="center" text={translation.home.whoWeAre.headline} />
        <Text>{translation.home.whoWeAre.text}</Text>
      </Container>
    </StyledWhoWeAre>
  )
}

export default WhoWeAre
