import styled from 'styled-components'
import bg from '../../../assets/image/home/block/bg.png'
import Headline from '../Headline/Headline'

export const StyledMottoBlock = styled.div`
  background-image: url(${bg});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  margin-top: 200px;
  @media (max-width: ${({ theme }) => theme.breakpoint.sm}) {
    background-size: auto 100%;
  }
`

export const Content = styled.div`
  display: flex;
  gap: 10px;
  flex-direction: column;
  align-items: flex-end;
  padding: 200px;
  @media (max-width: ${({ theme }) => theme.breakpoint.sm}) {
    padding: 200px 15px;
    align-items: center;
  }
`

export const CustomHeadline = styled(Headline)`
  color: ${({ theme }) => theme.color.font.white};
  text-align: right;
  width: 500px;
  @media (max-width: ${({ theme }) => theme.breakpoint.sm}) {
    text-align: left;
    width: 100%;
  }
`

export const CTA = styled.a`
  margin-top: 25px;
  width: fit-content;
  text-transform: uppercase;
  font-family: 'bold';
  background-color: ${({ theme }) => theme.color.background.secondary};
  color: ${({ theme }) => theme.color.font.secondary};
  padding: 7.5px 25px;
  text-decoration: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  &:hover {
    transform: scale(1.05);
  }
`
